import React from 'react'
import BreadCrumb from '../../components/elements/BreadCrumb'

const ManageGroupPermissions = () => {
  return (
    <>
    <BreadCrumb title='Role Permission Settings' from='Settings' to='Manage Roles & Permissions' back={true} />

    </>
  )
}

export default ManageGroupPermissions