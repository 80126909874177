import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../components/elements/BreadCrumb'
import { Button, Card, Col, Form } from 'react-bootstrap'
import Row from 'react-bootstrap/Row';
import Axios from 'axios';
import AlertCustom from '../../components/elements/AlertCustom';
import { useNavigate } from 'react-router-dom';

const baseURL = process.env.REACT_APP_ENDPOINT

const ChangePassword = () => {

    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })
    const navigate = useNavigate()

    useEffect(() => {
        if (alertData.alertShow) {
            setTimeout(() => {
                setAlertData({
                    'alertShow': false,
                })
            }, 7000)
        }
    }, [alertData])


    const [data, setData] = useState({
        "old_password": '',
        "new_password": '',
        "confirm_password": ''
    })
    let tokenStr = localStorage.getItem('access_token')

    const handleChange = (e) => {
        setData((prev) => {
            const name = e.target.name
            const value = e.target.value
            return {
                ...prev, [name]: value
            }
        })
    }

    const formSubmit = (e) => {
        e.preventDefault()

        setData((prev) => {
            const name = e.target.name
            const value = e.target.value
            return {
                ...prev, [name]: value
            }
        })

        Axios.post(`${baseURL}/users/changePassword`, data, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                if (res.data.success === false) {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'warning',
                        'alertMessage': res.data.message
                    })
                } else {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })
                }
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )

    }

    return (
        <>
            <AlertCustom data={alertData} />
            <BreadCrumb title='Change Password' from='User' to='Password Update' back={true} />
            <Card className='elevated'>
                <Card.Body>
                    <Form onSubmit={formSubmit}>
                        <Form.Group as={Row} className="mb-3" controlId="oldPassword">
                            <Form.Label column sm={2}>
                                Current Password
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control type="password" placeholder="Current Password" name="old_password" value={data.old_password} onChange={handleChange} autocomplete="off" required />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="newPassword">
                            <Form.Label column sm={2}>
                                New Password
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control type="password" placeholder="New Password" name='new_password' value={data.new_password} onChange={handleChange} autocomplete="off" required />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="confirmPassword">
                            <Form.Label column sm={2}>
                                Confirm Password
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control type="password" placeholder="Confirm Password" name='confirm_password' value={data.confirm_password} onChange={handleChange} autocomplete="off" required />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm={{ span: 10, offset: 2 }}>
                                <Button type="submit">Update Password</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        </>
    )
}

export default ChangePassword