import {createContext, useContext, useReducer} from "react";

const AppStateContext = createContext();
const AppDispatcherContext = createContext()

const initialUserState = {
    serviceError: {},
    serviceSuccess: {
        statusMessage : ''
    }
}

const appContextReducer = (state, action) => {
    switch (action.type) {
        case 'serviceError': {
            const serviceError = action.payload;
            return {...state, serviceError: serviceError};
        }
        case 'serviceSuccess':{
            return {...state, serviceSuccess: action.payload};
        }
    }
};

const ApplicationContext = ({children}) => {
    const [state, dispatch] = useReducer(appContextReducer, initialUserState)
    return (
        <AppDispatcherContext.Provider value={dispatch}>
            <AppStateContext.Provider value={state}>{children}</AppStateContext.Provider>
        </AppDispatcherContext.Provider>
    )
}

export  default  ApplicationContext;

export const useApplicationContext = () => [useContext(AppStateContext), useContext(AppDispatcherContext) ];