import {useEffect, useState} from 'react'
import {Table} from 'react-bootstrap'
import BreadCrumb from '../../components/elements/BreadCrumb'


import useAjax from "../../useAjax";
import Loader from "../../components/elements/loader/Loader";
import Pagination from '../../components/elements/Pagination';
import EditProfileModal from "./EditProfileModal";
import manageUserTableHeader from "./manageUserTableHeader";
import DynamicTableHeader from "./DynamicTableHeader";

const baseURL = process.env.REACT_APP_ENDPOINT
const tableOptions = {
    'styleClass': 'custom-datatable',
    'sortBy': 'email',
    'sortingIconDown': 'arrow_drop_down',
    'sortingIconUp': 'arrow_drop_up',
    'searchBar': true,
    'searchBarPlaceholder': 'Search',
    'pageNo': 1,
    'perPage': true,
    'perPageLabel': 'Items per page',
    'perPageDefault': 50,
    'perPageOptions': [10, 20, 50],
    'pagination': true,
    'paginationOptions': {
        'styleClass': 'pagination',
        'labelLatest': '',
        'iconLatest': 'New',
        'labelOldest': '',
        'iconOldest': 'Old',
        'labelPrevious': 'Prev',
        'iconPrevious': 'Prev',
        'iconNext': '',
        'labelNext': 'Next'
    }
}

const ManageUsers = () => {

    const [data, setData] = useState([])
    const [profileToEdit, setProfileToEdit] = useState(null);

    const [getUserList, userData, isLoadingUserData] = useAjax('get', `${baseURL}/users`);


    const [deleteUserCall, deleteUserCallSuccess] = useAjax('delete');

    const [searchText, setSearchText] = useState(null)
    const [paramSearch, setParamSearch] = useState('')
    const [paginationCount, setPaginationCount] = useState()
    const [totalCount, setTotalCount] = useState()
    const [pageNo, setPageNo] = useState(tableOptions.pageNo)
    const [perPage, setPerPage] = useState(tableOptions.perPageDefault)
    const [sortOrder, setSortOrder] = useState('asc')
    const [activeSortHeader, setActiveSortHeader] = useState('')
    const [onProfileUpdate, setProfileUpdateSuccess] = useState(null);

    useEffect(() => {
        setData(userData?.result.data);
        setTotalCount(userData?.result.total);
        setPaginationCount(() => {
            return Math.ceil(userData?.result.total / perPage)
        })
    }, [userData]);

    const handleDelete = (deleteId) => {
        if (window.confirm("Are you sure to delete this User?")) {
            deleteUserCall({URL: `${baseURL}/users/${deleteId}`});
        }
    }

    const statusViewer = (val) => {
        if (val === 1) {
            return <span className='badge bg-success'>Active</span>
        } else if (val === 0) {
            return <span className='badge bg-warning'>In-Active</span>
        }
    }

    //Datatabel
    const onHeaderClick = (_activeHeadKey, _sortOrder) => {
        setSortOrder(_sortOrder)
        setActiveSortHeader(_activeHeadKey)
    }

    const selectedPagination = (pageNumber) => {
        setPageNo(pageNumber)
    }

    const handleChangeSearchText = (e) => {
        const searchTerm = e.target.value
        setSearchText(searchTerm)
        if (searchText != null) {
            setParamSearch(`param_search=${e.target.value}&`)
        }

        if (searchTerm.length > 2) {
            getUserList({
                URL: `${baseURL}/users?${paramSearch}order_type=${sortOrder}&order_by=${activeSortHeader}&per_page=${perPage}&page=${pageNo}`
            })
        } else if (searchTerm.length === 0) {
            getUserList({
                URL: `${baseURL}/users?order_type=${sortOrder}&order_by=${activeSortHeader}&per_page=${perPage}&page=${pageNo}`
            })
        }
    }

    useEffect(() => {
        getUserList({
            URL: `${baseURL}/users?${paramSearch}order_type=${sortOrder}&order_by=${activeSortHeader}&per_page=${perPage}&page=${pageNo}`
        })
    }, [onProfileUpdate, deleteUserCallSuccess, perPage, pageNo, activeSortHeader, sortOrder]);

    return (
        <>
            <BreadCrumb title='All User' from='Settings' to='Manage User' back={true}/>
            <div className="card p-3">
                <div className="d-flex justify-content-between pb-3 table-filter-control">
                    <div>
                        <input type="search" name="table-global" placeholder={tableOptions.searchBarPlaceholder}
                               onChange={handleChangeSearchText}/>
                    </div>
                    <div>
                        <select name="h" value={perPage} onChange={(e) => setPerPage(e.target.value)}>
                            {
                                tableOptions.perPageOptions.map((item) => {
                                    return (
                                        <option value={item}>{item}</option>
                                    )
                                })
                            }
                        </select>
                        <span className="ml-1">{tableOptions.perPageLabel}</span>
                    </div>
                </div>

                <Table striped bordered hover className='custom-datatable' style={{fontSize: '12px'}}>
                    <DynamicTableHeader activeHeadKey={null} headers={manageUserTableHeader}
                                        onHeaderClick={onHeaderClick}/>
                    {isLoadingUserData && !data && (<Loader/>)}
                    {
                        data && (
                            (<tbody className={`${isLoadingUserData? 'loading' : 'loaded'}`}>
                            {
                                data && data.map((item, key) => {
                                    const {id, first_name, last_name, email, country, affiliation, status} = item
                                    return (
                                        <tr key={email}>
                                            {/* <td>{key + 1}</td> */}
                                            <td>{first_name}</td>
                                            <td>{last_name}</td>
                                            <td>{email}</td>
                                            <td>{country}</td>
                                            <td>{affiliation}</td>
                                            <td>{statusViewer(status)}</td>
                                            <td>
                                                <button
                                                    className='btn btn-light btn-xs elevated btn-classic me-2 mb-1'
                                                    onClick={() => setProfileToEdit(id)}><span
                                                    className="material-icons text-info">edit</span> Edit
                                                </button>
                                                <button
                                                    className='btn btn-light btn-xs elevated btn-classic me-2 mb-1'
                                                    onClick={() => handleDelete(id)}><span
                                                    className="material-icons text-danger">delete</span> Delete
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>)
                        )
                    }
                </Table>

                {paginationCount > 1 && (
                    <div className="d-flex justify-content-between">
                        <Pagination data={paginationCount} callback={selectedPagination}
                                    options={tableOptions.paginationOptions}/>
                        <div>
                            Total <strong>{totalCount}</strong> records
                        </div>
                    </div>
                )}

            </div>
            <EditProfileModal profileId={profileToEdit} onProfileUpdateComplete={setProfileUpdateSuccess}/>
        </>
    )
}

export default ManageUsers