
import React, {useState} from 'react'
import Header from '../Admin/Header'
import {Outlet} from 'react-router-dom'
import Sidebar from './sidebar/Sidebar'

const Admin = () => {

    //Sidebar toggle control
    const [toggle, setToggle] = useState(true)
    const handelToggle = () => {
        setToggle(toggle => !toggle)
    }
    let tgClass = toggle ? 'active' : ''

    return (
        <>
            {/* <Header sidebarAction={handelToggle} /> */}
            <div className='sidebar-layout'>
                <aside sidebarState={toggle} className={`sidebar px-2 py-0 ${tgClass}`}>
                    <div className='mb-3 mt-2'>
                        <button onClick={handelToggle} className='btn sidebarHandel'><span
                            className="material-icons">menu</span></button>
                        <span className="logo">
                            <svg width="301" height="77" viewBox="0 0 301 77" xmlns="http://www.w3.org/2000/svg"><text
                                id="EASYMEET" x="3" y="74" fontFamily="Impact" fontSize="74" fontWeight="800"
                                fill="#ffffff">EASYMEET</text></svg>
                        </span>
                    </div>
                    <Sidebar/>
                </aside>
                <main className='p-3'>
                    <Outlet/>
                </main>
            </div>
        </>
    )
}

export default Admin