import React from 'react';

const variantDetails = {
    "pageHeader": {
        width: "150",
        height: "40",
        fill: "#0369F5"
    }
}

const Logo = ({variant = "default"}) => {
    return (
        <div>
            <svg width={variantDetails[variant].width} height={variantDetails[variant].height} viewBox="0 0 301 77"
                 xmlns="http://www.w3.org/2000/svg">
                <text id="EASYMEET" x="3" y="74" font-family="Impact" font-size="74" font-weight="800"
                      fill={variantDetails[variant].fill}>EASYMEET
                </text>
            </svg>
        </div>
    );
};

export default Logo;