import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'

const SubNavLabel = ({title, url, icon}) => {

    const [dropDownActive, setDropDownActive] = useState(false)
    const elementRef = useRef(null)

    const handelSubNavDropDown = (e) => {
        e.preventDefault()
        setDropDownActive(dropDownActive => !dropDownActive)
    }

    const handleDocumentClick = (event) => {
        if (elementRef.current && !elementRef.current.contains(event.target)) {
            setDropDownActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    return (
        <NavLink to={url} title={title} onClick={handelSubNavDropDown} className={dropDownActive ? "dropActive" : null} ref={elementRef}><span className="material-icons">{icon}</span><span className='title'>{title}</span></NavLink>
    )
}

export default SubNavLabel