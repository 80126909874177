import {useState} from "react";
import Axios from "axios";
import {useApplicationContext} from "./contexts/ApplicationContext";



const useAjax = (method = 'get', apiURL) => {
    const [response, setAjaxResponse] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [, dispatchApplicationContext] = useApplicationContext();


    const handleHTTPError = (error) => {
        let payload = null;

        if(error?.code === 'ERR_NETWORK'){
            payload = {
                statusCode: 601,
                statusMessage: "Your internet is unstable or disconnected." }
            dispatchApplicationContext({ type: "serviceError", payload: payload});
            return;
        }
        if (error?.response?.status === 401) {
            payload = {
                statusCode: 401,
                statusMessage: error.response.data }

        } else  {
             payload = {
                statusCode: 199,
                 statusMessage: error?.response?.data || error.toString()
            }
        }
        dispatchApplicationContext({ type: "serviceError", payload: payload});
    }

    const fire = (config) => {
        let tokenStr = localStorage.getItem('access_token');
        const defaultHeaders = {"Authorization": `Bearer ${tokenStr}`};

        const url = config?.URL || apiURL;
        const payload = config?.payload || null;
        const headers = config?.headers ? {...defaultHeaders, ...config.headers} : defaultHeaders;


        if (method === 'delete' || method === 'get') {
            Axios[method](url, {headers})
                .then(res => {
                    setLoading(false)
                    setAjaxResponse(res.data);

                })
                .catch(error => {
                    setLoading(false)
                    handleHTTPError(error)
                })
        }

        if (method === 'post') {
            Axios.post(url,payload, {headers})
                .then(res => {
                    setLoading(false)
                    setAjaxResponse(res.data);
                })
                .catch(error => {
                    setLoading(false)
                    handleHTTPError(error)
                })
        }
        setLoading(true)

    }
    return [fire, response, isLoading];
}

export default useAjax;