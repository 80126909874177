export const userMenuItems  = [
    // {
    //     'title': 'Dashboard',
    //     'url':'/dashboard',
    //     'icon':'dashboard'
    // },
    {
        'title': 'Conference',
        'url':'/conference',
        'icon':'ballot',
        'submenu':[
            {
                'title': 'All Conference',
                'url':'/conference/allConference',
            },
            {
                'title': 'Add Conference',
                'url':'/conference/addConference',
            },
        ]
    },{
        'title': 'Profile',
        'url':'/user',
        'icon':'account_circle',
        'submenu':[
            {
                'title': 'Details',
                'url':'/user/profile',
            },
            {
                'title': 'Change Password',
                'url':'/user/changePassword',
            }
        ]
    }
]