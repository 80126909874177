import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import style from './BreadCrumb.module.css';

const BreadCrumb = ({ title, from, to, back }) => {
    const navigate = useNavigate()
    return (
        <>
            <div className={`${style.BreadCrumbWrapper} d-flex justify-content-between`}>
                <div>
                    {
                        title ? <h1 className="section-title">{title}</h1> : ''
                    }                    
                    <ol className="breadcrumb text-left">
                        <li><NavLink to={'/conference'}>Home</NavLink></li>
                        {
                            from ? <li className='active'>{from} </li> : ''
                        }
                        <li className='active'>{to}</li>
                    </ol>
                </div>
                {
                    back ? <div><NavLink onClick={() => navigate(-1)} className='btn btn-sm btn-light-low border-0'><span className="material-icons">chevron_left</span> Back</NavLink></div> : null
                }
                
            </div>
        </>
    )
}

export default BreadCrumb