import Axios from 'axios'
import React, { useState } from 'react'
import { Card, CardBody, Form, Row, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import BreadCrumb from '../../components/elements/BreadCrumb'
import ResearchArea from '../../data/ResearchArea'

import CountryList from '../../data/CountryList'
import TimeZone from '../../data/TimeZone'

import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates'
import RequiredInfo from '../../components/elements/RequiredInfo'

const baseURL = process.env.REACT_APP_ENDPOINT

const AddConference = () => {
    const navigate = useNavigate()

    const [data, setData] = useState({
        "event_name": "",
        "acronym_title": "",
        "event_web_page": "",
        "city": "",
        "venue": "",
        "state": "",
        "country": "",
        "timezone": "",
        "contact_email": "",
        "event_org_phone": "",
        "event_organizer": '',
        "event_org_web_page": '',
        // "start_date": "",
        // "end_date": "",
        "area_notes": "",
        "secondary_area_id": '',
        "primary_area_id": '',
        "estimated_no_of_submissions": ''
    })

    // Commented for now
    // const [dataFiles, setDataFiles] = useState({
    //     "banner": null,
    //     "logo": null,
    // })


    //Dates start
    const [dateRange, setDateRange] = useState({
        start_date: null,
        end_date: null,
    });

    const [focusedInput, setFocusedInput] = useState(null);

    const handleDateChange = ({ startDate, endDate }) => {
        const formattedStartDate = startDate ? startDate.format('YYYY-MM-DD') : null;
        const formattedEndDate = endDate ? endDate.format('YYYY-MM-DD') : null;

        setDateRange({ start_date: formattedStartDate, end_date: formattedEndDate });
    };

    const handleFocusChange = (focused) => {
        setFocusedInput(focused);
    };
    const isOutsideRange = (day) => {
        return day.isBefore(moment(), 'day'); // Disable dates before today
    };
    //Dates ends


    const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value

        setData((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }

    let tokenStr = localStorage.getItem('access_token')

    const handleSubmit = (e) => {
        e.preventDefault()
        // let subDates = {
        //     'start_date': date
        // }
        let formdata = {
            ...data,
            ...dateRange
            // ...dataFiles, // Commented for now
        }

        Axios.post(`${baseURL}/conference`, formdata, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                navigate('/conference/allConference')
            })
            .catch(error => {
                if (error.response.status === 401) {
                    alert("You do not have the permission or your session has been expired. Please login again to continue.")
                    navigate('/')
                } else {
                    alert("Something went wrong.")
                    console.log(error)
                }
            })
    }

    return (
        <>
            <BreadCrumb title='Add Conference' from='Conference' to='Add Conference' back={true} />
            <Card className='elevated'>
                <CardBody className='p-4'>
                    <RequiredInfo />
                    <Form onSubmit={handleSubmit}>
                        <legend>Conference Basic Information</legend>
                        <Row>
                            <Col md={6}>
                                <Form.Group className='mb-3 required' controlId="" label="Conference Title" >
                                    <Form.Label>Conference Title</Form.Label>
                                    <Form.Control type="text" placeholder="Conference Title" name='event_name' value={data.event_name} onChange={handleChange} required />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className='mb-3 required' controlId="" label="Acronym" >
                                <Form.Label>Acronym</Form.Label>
                                    <Form.Control type="text" placeholder="Acronym" name='acronym_title' value={data.acronym_title} onChange={handleChange} required />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Label>Conference Date</Form.Label>
                                <DateRangePicker
                                    block={true}
                                    startDate={dateRange.start_date ? moment(dateRange.start_date, 'YYYY-MM-DD') : null}
                                    startDateId="start_date_id"
                                    endDate={dateRange.end_date ? moment(dateRange.end_date, 'YYYY-MM-DD') : null}
                                    endDateId="end_date_id"
                                    onDatesChange={handleDateChange}
                                    focusedInput={focusedInput}
                                    onFocusChange={handleFocusChange}
                                    isOutsideRange={isOutsideRange}
                                    displayFormat="YYYY-MM-DD"
                                    readOnly={true}
                                    customArrowIcon={"To"}
                                />

                            </Col>
                            {/* <Col md={3}>
                                <FloatingLabel className='mb-3' controlId="" label="End Date" >
                                    <Form.Control type="date" placeholder="End Date" name='end_date' value={data.end_date} onChange={handleChange} />
                                    
                                </FloatingLabel>
                            </Col> */}
                            <Col md={3}>
                                <Form.Group className='mb-3 required' controlId="" label="Your Role" >
                                <Form.Label>Your Role</Form.Label>
                                    <Form.Control type="text" placeholder="Your Role" name='your_role' value={data.your_role} onChange={handleChange} required />                                    
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className='mb-3 required' controlId="" label="Estimated No Of Submissions" >
                                <Form.Label>Estimated No Of Submissions</Form.Label>
                                    <Form.Control type="text" placeholder="Estimated No Of Submissions" name='estimated_no_of_submissions' value={data.estimated_no_of_submissions} onChange={handleChange} required />                                    
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className='mb-3 required' controlId="" label="Primary Area" >
                                <Form.Label>Primary Area</Form.Label>
                                    {/* <Form.Control type="text" placeholder="Primary Area" name='primary_area_id' value={data.primary_area_id} onChange={handleChange} /> */}
                                    <Form.Select name='primary_area_id' value={data.primary_area_id} onChange={handleChange} required>
                                        <option>Select</option>
                                        {
                                            ResearchArea.map((item) => {
                                                const { value, label } = item
                                                return <option value={value}>{label}</option>
                                            })
                                        }
                                    </Form.Select>                                    
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className='mb-3 required' controlId="" label="Secondary Area" >
                                <Form.Label>Secondary Area</Form.Label>
                                    {/* <Form.Control type="text" placeholder="Secondary Area" name='secondary_area_id' value={data.secondary_area_id} onChange={handleChange} /> */}
                                    <Form.Select name='secondary_area_id' value={data.secondary_area_id} onChange={handleChange} required>
                                        <option>Select</option>
                                        {
                                            ResearchArea.map((item) => {
                                                const { value, label } = item
                                                return <option value={value}>{label}</option>
                                            })
                                        }
                                    </Form.Select>                                    
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className='mb-3 required' controlId="" label="Area Notes" >
                                <Form.Label>Area Notes</Form.Label>
                                    <Form.Control type="text" placeholder="Area Notes" name='area_notes' value={data.area_notes} onChange={handleChange} required />                                    
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className='mb-4' controlId="" label="Other Info" >
                                <Form.Label>Other Info</Form.Label>
                                    <Form.Control type="text" placeholder="Other Info" name='other_info' value={data.other_info} onChange={handleChange} />                                    
                                </Form.Group>
                            </Col>

                            {/** Commented for now */}
                            {/* <Col md={6}>
                                <Form.Group className='mb-4' controlId="" label="Banner" >
                                    <Form.Control type="file" placeholder="Banner" name='banner' onChange={handleChangeFile} />
                                    
                                </Form.Group>
                            </Col>
                            <Col md={6}>

                                <Form.Group className='mb-4' controlId="" label="Logo" >
                                    <Form.Control type="file" placeholder="Logo" name='logo' onChange={handleChangeFile} />
                                    
                                </Form.Group>
                            </Col> */}
                        </Row>

                        <legend>Conference Detailed Information</legend>
                        <Row>
                            <Col md={6}>
                                <Form.Group className='mb-3 required' controlId="" label="Event Web Page" >
                                <Form.Label>Event Web Page</Form.Label>
                                    <Form.Control type="text" placeholder="Event Web Page" name='event_web_page' value={data.event_web_page} onChange={handleChange} required />                                    
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className='mb-3 required' controlId="" label="Venue" >
                                <Form.Label>Venue</Form.Label>
                                    <Form.Control type="text" placeholder="Venue" name='venue' value={data.venue} onChange={handleChange} required />                                    
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className='mb-3 required' controlId="" label="City" >
                                <Form.Label>City</Form.Label>
                                    <Form.Control type="text" placeholder="City" name='city' value={data.city} onChange={handleChange} required />                                    
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className='mb-3 required' controlId="" label="State" >
                                <Form.Label>State</Form.Label>
                                    <Form.Control type="text" placeholder="State" name='state' value={data.state} onChange={handleChange} required />                                    
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className='mb-3 required' controlId="" label="Country" >
                                <Form.Label>Country</Form.Label>
                                    {/* <Form.Control type="text" placeholder="Country" name='country' value={data.country} onChange={handleChange} required /> */}
                                    <Form.Select name='country' value={data.country} onChange={handleChange} required>
                                        <option>Select</option>
                                        {
                                            CountryList.map((item, key) => {
                                                return <option value={item} key={key}>{item}</option>
                                            })
                                        }
                                    </Form.Select>                                    
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className='mb-3 required' controlId="" label="Timezone" >
                                <Form.Label>Timezone</Form.Label>
                                    {/* <Form.Control type="text" placeholder="Timezone" name='timezone' value={data.timezone} onChange={handleChange} required />
                                     */}

                                    <Form.Select name='timezone' value={data.timezone} onChange={handleChange} required>
                                        <option>Select</option>
                                        {
                                            TimeZone.map((item, key) => {
                                                const{value, label}= item
                                                return <option value={value} key={key}>{label}</option>
                                            })
                                        }

                                    </Form.Select>
                                    
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className='mb-3 required' controlId="" label="Contact Email" >
                                <Form.Label>Contact Email</Form.Label>
                                    <Form.Control type="email" placeholder="Contact Email" name='contact_email' value={data.contact_email} onChange={handleChange} required />                                    
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className='mb-3 required' controlId="" label="Event Organaization Phone" >
                                <Form.Label>Event Organaization Phone</Form.Label>
                                    <Form.Control type="text" placeholder="Event Organaization Phone" name='event_org_phone' value={data.event_org_phone} onChange={handleChange} required />                                    
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className='mb-3 required' controlId="" label="Event Organaizer" >
                                <Form.Label>Event Organaizer</Form.Label>
                                    <Form.Control type="text" placeholder="Event Organaizer" name='event_organizer' value={data.event_organizer} onChange={handleChange} required />                                    
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className='mb-3 required' controlId="" label="Event Organaizer Webpage" >
                                <Form.Label>Event Organaizer Webpage</Form.Label>
                                    <Form.Control type="text" placeholder="Event Organaizer Webpage" name='event_org_web_page' value={data.event_org_web_page} onChange={handleChange} required />                                    
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <div className='mb-3 border-top py-3 align-content-end' >
                                <Button type='submit' className="btn btn-large btn-primary">Submit</Button>
                            </div>
                        </Row>

                    </Form>
                </CardBody>
            </Card>
        </>
    )
}

export default AddConference