const ReviewerEvaluation = [
    {
        'value': 'Strong Accept (3)',
        'label': 'Strong Accept (3)',
    },
    {
        'value': 'Accept (2)',
        'label': 'Accept (2)',
    }, {
        'value': 'Weak Accept (1)',
        'label': 'Weak Accept (1)',
    }, {
        'value': 'Borderline Paper (0)',
        'label': 'Borderline Paper (0)',
    }, {
        'value': 'Weak Reject (-1)',
        'label': 'Weak Reject (-1)',
    }, {
        'value': 'Reject (-2)',
        'label': 'Reject (-2)',
    }, {
        'value': 'Strong Reject (-3)',
        'label': 'Strong Reject (-3)',
    }
]

export default ReviewerEvaluation