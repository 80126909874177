import { Link, NavLink, useParams } from 'react-router-dom'
import { Container, Nav, Navbar } from "react-bootstrap"

import { useEffect, useState } from 'react'
import organizingCommitteeType from '../../data/OrganizingCommitteeType'
import cfpStyles from "./cfp.module.css";
import useAjax from "../../useAjax";
import Logo from "../../components/elements/logo";


const baseURL = process.env.REACT_APP_ENDPOINT

const CfpView = () => {

    const detailsId = useParams()
    const [octData, setOctData] = useState([])
    const [rightData, setRightData] = useState([]);

    const [getPageData, getPageDataSuccess] = useAjax('get');


    useEffect(() => {
        if (getPageDataSuccess) {
            setRightData(getPageDataSuccess.result)

            const octLookup = organizingCommitteeType.reduce((acc, { value, label }) => {
                acc[value] = label;
                return acc;
            }, {});

            let cfp_data = getPageDataSuccess.result.cfp_data
            //Merge with organizing_Committee_Type
            const merged_label = cfp_data.map(item => ({
                id: item.id,
                organizing_committee_type: item.organizing_committee_type,
                organizing_committee_type_label: octLookup[item.organizing_committee_type],
                first_name: item.first_name,
                last_name: item.last_name,
                sequence: item.sequence,
                affiliation: item.affiliation,
                country: item.country
            }));

            //Group by label
            const grouped_label = Object.groupBy(merged_label, ({ organizing_committee_type_label }) => organizing_committee_type_label)

            Object.keys(grouped_label).forEach(key => {
                grouped_label[key].sort((a, b) => a.sequence - b.sequence);
            });

            const result = Object.entries(grouped_label).map(([key, value]) => ({
                oct: key,
                list: value.sort((a, b) => a.sequence - b.sequence)
            }));

            setOctData(result)
        }
    }, [getPageDataSuccess]);

    useEffect(() => {
        getPageData({
            URL: `${baseURL}/site/cfp/${detailsId.id}`
        });
    }, [])

    function createMarkup() {
        return { __html: rightData.cfp_details };
    }

    return (
        <div className={cfpStyles.cfpViewPage}>
            <div className={`${cfpStyles.header} sticky-top`}>
                <Navbar expand="lg" className="navbar-inverse  bg-body-tertiary">
                    <Container>
                        <Link to={"https://easymeet.ws/"} title='EasyMeet'><Logo variant={"pageHeader"} /> </Link>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <div className='d-flex justify-content-end' style={{flexBasis: 'auto !important', flexGrow: 1}}><NavLink to={'/'} className='btn btn-primary'><span className="material-icons">login</span> Login</NavLink></div>
                        <Navbar.Collapse>
                            <Nav className="ms-auto">
                                {
                                    rightData.cfp_file ? <a className='btn btn-primary'
                                        href={`data:${rightData.cfp_file_mime_type};base64,${rightData.cfp_file}`}
                                        download={detailsId.id}><span
                                            className="material-icons">cloud_download</span> Download</a> : ''
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            <main className={`classicStyle container ${cfpStyles.freeContent} `}>
                {
                    rightData.banner ? <img src={`data:${rightData.banner_mime_type};base64,${rightData.banner}`}
                        className='banner curved w-100 my-2' alt='Banner' />
                        : <>
                            <div className='card bg-fill-light-gray my-3'>
                                <div className='card-body'><h3 className='text-center'
                                    style={{ 'fontSize': '4rem' }}>{rightData.acronym_title}</h3>
                                    <h1 className='text-center my-4'
                                        style={{ 'fontSize': '2.5rem', 'fontWeight': '700' }}>{rightData.event_name}</h1>
                                </div>
                            </div>
                        </>
                }

                <div className="card p-4 my-3">
                    <div className="card-body">
                        <div className='row'>
                            <div className="col-4 border-end">
                                <h2>Organizing Committee</h2>
                                <br />
                                {
                                    octData.map((item) => {
                                        let { oct, list } = item
                                        return (

                                            oct === "Conference Management" ? '' :
                                                <>
                                                    <h4><strong>{oct}</strong></h4>
                                                    <ul className='list-unstyled'>
                                                        {
                                                            list.map((item) => {
                                                                let { first_name, last_name, affiliation, country } = item
                                                                return (
                                                                    <>
                                                                        <div
                                                                            className='d-flex justify-content-between mb-1'>
                                                                            <div className='col'>
                                                                                {first_name} {last_name}, <small>{affiliation}, {country}</small>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </>
                                        )
                                    })
                                }
                            </div>
                            <div className={`col-8 ${cfpStyles.paperDetails} `}>
                                <h2 className={cfpStyles.header2}>Call For Papers</h2>
                                <br />
                                <div dangerouslySetInnerHTML={createMarkup()}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <footer className="py-3 text-center">
                Copyright © { } Easy Meet. All rights reserved
            </footer>
        </div>
    )
}

export default CfpView