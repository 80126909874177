import React, { useEffect, useState } from 'react'
import { Card, CardBody, FloatingLabel, Form, FormGroup } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import AlertCustom from '../../components/elements/AlertCustom'
import Axios from 'axios'
import styles from './login.module.css';

const baseURL = process.env.REACT_APP_ENDPOINT

const SetPassword = () => {
    const navigate = useNavigate()

    const location = useLocation()
    const linkToken = new URLSearchParams(location.search).get('link')

    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })

    const [data, setData] = useState({
        new_password: '',
        confirm_password: '',
        token: ''
    })

    //Action
    useEffect(() => {
        if (alertData.alertShow) {
            setTimeout(() => {
                setAlertData({
                    'alertShow': false,
                })
            }, 7000)
        }
    }, [alertData])

    const handleChange = (e) => {
        setData((prev) => {
            const name = e.target.name
            const value = e.target.value
            return {
                ...prev, [name]: value
            }
        })
    }

    const formSubmit = (e) => {
        e.preventDefault()

        setData((prev) => {
            const name = e.target.name
            const value = e.target.value
            return {
                ...prev, [name]: value
            }
        })
        data.token = linkToken

        Axios.post(`${baseURL}/set_password`, data)
            .then((res) => {
                if (res.data.success === false) {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'warning',
                        'alertMessage': res.data.message
                    })
                } else {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })

                    setTimeout(() => {
                        navigate('/')
                    }, 3000)
                }
            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data.message
                    })
                }
            )

    }
    return (
        <>
            <AlertCustom data={alertData} />
            <div className={styles.loginPage}>
                <div className={styles.leftPanel}>
                    <div>
                        <svg width="301" height="77" viewBox="0 0 301 77" xmlns="http://www.w3.org/2000/svg">
                            <text id="EASYMEET" x="3" y="74" fontFamily="Impact" fontSize="74" fontWeight="800" fill="#ffffff">EASYMEET</text>
                        </svg>
                        <div className={styles.leftPanelMessage}>The easiest platform for creating conferences.</div>
                    </div>

                    <img className={styles.leftPanelOverlayImg} src="/images/1206.jpg" alt={''} />
                </div>
                <div className={styles.rightPanel}>
                    <div className='d-flex justify-content-center align-items-center login'>
                        <Card className='login-card'>
                            <CardBody>
                                <Form onSubmit={formSubmit} className='w280'>
                                    <h1 className='text-center'>Set New Password</h1>
                                    {/* <Link to={'/'}><img src={loginLogo} className='d-block mx-auto' alt='Eeasy Meet' /></Link> */}
                                    <FormGroup className="mb-3 mt-4">
                                        <FloatingLabel controlId="floatingInput" label="New Password">
                                            <Form.Control type="password" name='new_password' placeholder="New Password" value={data.new_password} onChange={handleChange} required />
                                            <span></span>
                                        </FloatingLabel>
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <FloatingLabel controlId="floatingInput" label="Confirm Password">
                                            <Form.Control type="password" name='confirm_password' placeholder="Confirm Password" value={data.confirm_password} onChange={handleChange} required />
                                            <span></span>
                                        </FloatingLabel>
                                    </FormGroup>
                                    <input type="submit" value="Submit" className='btn btn-primary w-100' />
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SetPassword