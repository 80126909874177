import {useEffect, useState} from "react";
import AlertCustom from "../../elements/AlertCustom";
import {useApplicationContext} from "../../../contexts/ApplicationContext";

const closedAlertData = {
    'alertShow': false,
    'alertVariant': null,
    'alertMessage': ''
}

const getSuccessMessage = (statusMessage) =>  {
    return {
        'alertShow': true,
        'alertVariant': 'success',
        'alertMessage': statusMessage || 'Loading...'
    }
}

const SuccessHandler = () => {
    const [statusData, setStatusData] = useState(closedAlertData);
    const [ApplicationContext, dispatchApplicationContext] = useApplicationContext();

    useEffect( () => {
        const successMessage = ApplicationContext?.serviceSuccess?.statusMessage;

        if(successMessage !== '' && statusData.alertShow === false){
            setStatusData(getSuccessMessage(successMessage));
            setTimeout(()=>{
                dispatchApplicationContext({type:'serviceSuccess', payload: {statusMessage : ''} })
            }, 2000);
        }  else {

            setStatusData(closedAlertData);
        }
    }, [ApplicationContext?.serviceSuccess]);

    return (<AlertCustom data={statusData}/>)
}

export default SuccessHandler
