import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap"

const AlertCustom = (props) => {
    const alertShow = props.data.alertShow
    const alertVariant = props.data.alertVariant
    const alertMessage = props.data.alertMessage

    return (
        <Alert show={alertShow} variant={alertVariant} className='floating absolute-alert'>
            {alertMessage}
        </Alert>
    )
}

export default AlertCustom