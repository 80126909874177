import React, { useState } from 'react'
import BreadCrumb from '../../components/elements/BreadCrumb'
import { useLocation } from 'react-router-dom'

const Reports = () => {
    const prevStage = useLocation()
    
    const [dataStage, setDataStage] = useState(prevStage)

    const detailsId = dataStage.state || 0

    return (
        <>
            <BreadCrumb title="Report" from='Report' back={true} />

        </>
    )
}

export default Reports