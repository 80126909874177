const headers = [
    {
        'label': 'First Name',
        'key': 'first_name',
        'sortable': true
    },
    {
        'label': 'Last Name',
        'key': 'last_name',
        'sortable': true
    },
    {
        'label': 'Email',
        'key': 'email',
        'sortable': true
    },
    {
        'label': 'Country',
        'key': 'country',
        'sortable': true
    },
    {
        'label': 'Affiliation',
        'key': 'affiliation',
        'sortable': true
    },
    {
        'label': 'Status',
        'key': 'status',
        'sortable': true
    },
    {
        'label': 'Actions',
        'key': '',
        'sortable': false
    },
]

export default  headers;