const StatusList = [
    {
        'value': 0,
        'label': "Submitted",
    },
    {
        'value': 5,
        'label': "Five",
    }, {
        'value': 10,
        'label': "Ten",
    }, {
        'value': 15,
        'label': "Fiften",
    }, {
        'value': 20,
        'label': "Twenty",
    }, {
        'value': 25,
        'label': "Blocked",
    }, {
        'value': 30,
        'label': "Withdrawn",
    }, {
        'value': 35,
        'label': "Thiry Five",
    }, {
        'value': 40,
        'label': "Fourty",
    }
]

export default StatusList