import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, FloatingLabel, Form, Row, Col, Button, Tab, Nav, CardFooter } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BreadCrumb from '../../components/elements/BreadCrumb'
import ResearchArea from '../../data/ResearchArea'
import CountryList from '../../data/CountryList'
import TimeZone from '../../data/TimeZone'

import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates'
import { SingleDatePicker } from 'react-dates';

const baseURL = process.env.REACT_APP_ENDPOINT

const EditConference = () => {
    const navigate = useNavigate()
    // const detailsId = useParams()
    const prevStage = useLocation()
    
    const [dataStage, setDataStage] = useState(prevStage)

    const detailsId = dataStage.state || 0

    const [data, setData] = useState({
        "event_name": "",
        "acronym_title": "",
        "event_web_page": "",
        "city": "",
        "venue": "",
        "state": "",
        "country": "",
        "timezone": "",
        "contact_email": "",
        "event_org_phone": "",
        // "start_date": '',
        // "end_date": '',
        "area_notes": "",
        "secondary_area_id": '',
        "primary_area_id": '',
        "estimated_no_of_submissions": '',
        "publisher": "",
        "series": "",
        "geographic_focus": "",
        "language": "",
        "date_format": "",
        "timezone_for_deadline": "",
        "timezone_for_session": "",
        "topic_focus": "",
        "nature_of_submission": "",
        "pre_submission_abstract_allowed": 0,
        "submission_topics": "",
        "disable_abstract_submission_form": 1,
        "disable_multiple_authors": 1,
        "text_abstracts_max_length": "",
        "submission_instructions_for_authors": "",
        "submission_templates_for_authors": "",
        "additional_fields_submission_form": "",
        "file_upload_fields_submission_form": "",
        "author_selecting_presenter": 1,
        "new_submission_allowed": 1,
        "submission_update_allowed": 1,
        "automatic_submission_page_opening_or_closure": 1,
        "is_paper_binding_enabled": 1,
        "is_viewing_bids_of_pc_members_chairs_enabled": 1,
        "is_assignment_submitted_papers_to_program_committee_enabled": 1,
        "number_of_reviewers_per_paper": "",
        "reviewing_model": "",
        "are_reviewier_names_visible_to_pc": 1,
        "start_menu_is": "",
        "review_menu_is": "",
        "permit_pc_members_to_enter_reviews": 1,
        "leading_reviewers_can_update_metareviews": 1,
        "access_to_reviews_by_ordinary_pc_members": 1,
        "use_offline_review_forms": 1,
        "review_can_have_attachments": 1,
        "review_form": "",
        "decisions": "",
        "reviewer": "",
        "instructions": "",
        "use_subviewers": 1,
        "are_submissions_anonymour_for_subreviewers": 1,
        "permit_subreviewers_participate_in_discussion": 1,
        "are_track_chairs_allowed_reviews_to_authors": 1,
        "submission_web_page": "",
        // "license_expiry_date": '',
        "event_approval_email": "",
        "event_support_emails": "",
        "event_management_url": "",
    })


    const [dataFiles, setDataFiles] = useState({
        "banner": null,
        "logo": null,
    })
    //Dates start
    const [dateRange, setDateRange] = useState({
        start_date: null,
        end_date: null,
    });

    const [focusedInput, setFocusedInput] = useState(null);

    const handleDateChange = ({ startDate, endDate }) => {
        const formattedStartDate = startDate ? startDate.format('YYYY-MM-DD') : null;
        const formattedEndDate = endDate ? endDate.format('YYYY-MM-DD') : null;

        setDateRange({ start_date: formattedStartDate, end_date: formattedEndDate });
    };

    const handleFocusChange = (focused) => {
        setFocusedInput(focused);
    };
    const isOutsideRange = (day) => {
        return day.isBefore(moment(), 'day'); // Disable dates before today
    };
    //Dates ends

    //Single Date ends
    const [licenseExpiryDate, setLicenseExpiryDate] = useState();
    const [licenseDate, setLicenseDate] = useState({
        "license_expiry_date": '',
    });
    const [focused, setFocused] = useState(false);

    const handleLicenseExpiryDateChange = (newDate) => {
        const formattedLicenseDate = newDate ? newDate.format('YYYY-MM-DD') : null;
        setLicenseExpiryDate(formattedLicenseDate);
        setLicenseDate({
            "license_expiry_date": formattedLicenseDate,
        })
    };
    //Single Date

    const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value

        setData((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }
    const handleChangeFile = (e) => {
        let name = e.target.name
        let value = e.target.files[0]

        setDataFiles((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }
    let tokenStr = localStorage.getItem('access_token')

    const handleSubmit = (e) => {
        e.preventDefault()
        let formdata = {
            ...data,
            ...dataFiles,
            ...dateRange,
            ...licenseDate
        }
        const confirmation = window.confirm("Are you sure to update this Conference?")
        if (confirmation) {
            Axios.post(`${baseURL}/conference/update/${detailsId.id}`, formdata, {
                headers: {
                    "Authorization": `Bearer ${tokenStr}`,
                    "Content-type": "multipart/form-data"
                }
            })
                .then((res) => {
                    navigate('/conference/allConference')
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        alert("You do not have the permission or your session has been expired. Please login again to continue.")
                        navigate('/')
                    } else {
                        alert("Something went wrong.")
                    }
                })
        }
    }

    useEffect(() => {
        if (detailsId.id > 0) {

            Axios.get(`${baseURL}/conference/${detailsId.id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then(res => {
                    setData(res.data.result[0])
                    setDateRange({
                        start_date: res.data.result[0].start_date,
                        end_date: res.data.result[0].end_date,
                    })
                    setLicenseExpiryDate(res.data.result[0].license_expiry_date)
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        alert("You do not have the permission or your session has been expired. Please login again to continue.")
                        navigate('/')
                    } else {
                        alert("Something went wrong.")
                    }
                })
        }
    }, [detailsId])

    return (
        <>
            {
                detailsId.id > 0 ?
                    <BreadCrumb title='Edit Conference' from='Conference' to='Edit Conference' back={true} /> : ''
                    // <BreadCrumb title='Add Conference' from='Conference' to='Add Conference' back={true} />
            }

            <Card className='elevated'>

                <Form onSubmit={handleSubmit}>
                    <Tab.Container defaultActiveKey="first">
                        <Nav variant="tabs" className="m-2 custom-tabs border-0" fill>
                            <Nav.Item>
                                <Nav.Link eventKey="first">Conference Basic Information</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Address and Contact Details</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">Submission Details</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="fourth">Review Details</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="fifth">Other Details</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <CardBody className='p-4'>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Conference Title">
                                                <Form.Label>Conference Title</Form.Label>
                                                <Form.Control type="text" placeholder="Conference Title"
                                                    name='event_name' value={data.event_name}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Acronym">
                                                <Form.Label>Acronym</Form.Label>
                                                <Form.Control type="text" placeholder="Acronym" name='acronym_title'
                                                    value={data.acronym_title} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label>Conference Date</Form.Label>
                                            <DateRangePicker
                                                block={true}
                                                startDate={dateRange.start_date ? moment(dateRange.start_date, 'YYYY-MM-DD') : null}
                                                startDateId="start_date_id"
                                                endDate={dateRange.end_date ? moment(dateRange.end_date, 'YYYY-MM-DD') : null}
                                                endDateId="end_date_id"
                                                onDatesChange={handleDateChange}
                                                focusedInput={focusedInput}
                                                onFocusChange={handleFocusChange}
                                                isOutsideRange={isOutsideRange}
                                                displayFormat="YYYY-MM-DD"
                                                readOnly={true}
                                                customArrowIcon={"To"}
                                            // displayFormat={() => moment().format('YYYY-MM-DD')}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className='mb-4' controlId="" label="Your Role">
                                                <Form.Label>Your Role</Form.Label>
                                                <Form.Control type="text" placeholder="Your Role" name='your_role'
                                                    value={data.your_role} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className='mb-4' controlId=""
                                                label="Estimated No Of Submissions">
                                                <Form.Label>Estimated No Of Submissions</Form.Label>
                                                <Form.Control type="text" placeholder="Estimated No Of Submissions"
                                                    name='estimated_no_of_submissions'
                                                    value={data.estimated_no_of_submissions}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Primary Area">
                                                <Form.Label>Primary Area</Form.Label>
                                                <Form.Select name='primary_area_id' value={data.primary_area_id}
                                                    onChange={handleChange} required>
                                                    <option>Select</option>
                                                    {
                                                        ResearchArea.map((item, key) => {
                                                            const { value, label } = item
                                                            return <option value={value} key={key}>{label}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Secondary Area">
                                                <Form.Label>Secondary Area</Form.Label>
                                                <Form.Select name='secondary_area_id' value={data.secondary_area_id}
                                                    onChange={handleChange} required>
                                                    <option>Select</option>
                                                    {
                                                        ResearchArea.map((item, key) => {
                                                            const { value, label } = item
                                                            return <option value={value} key={key}>{label}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Area Notes">
                                                <Form.Label>Area Notes</Form.Label>
                                                <Form.Control type="text" placeholder="Area Notes" name='area_notes'
                                                    value={data.area_notes} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Other Info">
                                                <Form.Label>Other Info</Form.Label>
                                                <Form.Control type="text" placeholder="Other Info" name='other_info'
                                                    value={data.other_info} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Publisher">
                                                <Form.Label>Publisher</Form.Label>
                                                <Form.Control type="text" placeholder="Publisher" name='publisher'
                                                    value={data.publisher} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className='mb-4' controlId="" label="Series">
                                                <Form.Label>Series</Form.Label>
                                                <Form.Control type="text" placeholder="Series" name='series'
                                                    value={data.series} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className='mb-4' controlId="" label="Geographic Focus">
                                                <Form.Label>Geographic Focus</Form.Label>
                                                <Form.Control type="text" placeholder="Geographic Focus"
                                                    name='geographic_focus' value={data.geographic_focus}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className='mb-4' controlId="" label="Language">
                                                <Form.Label>Language</Form.Label>
                                                <Form.Control type="text" placeholder="Language" name='language'
                                                    value={data.language} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className='mb-4' controlId="" label="Date Format">
                                                <Form.Label>Date Format</Form.Label>
                                                <Form.Control type="text" placeholder="Date Format" name='date_format'
                                                    value={data.date_format} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className='mb-4' controlId="" label="Timezone For Deadline">
                                                <Form.Label>Timezone For Deadline</Form.Label>
                                                <Form.Select name='timezone_for_deadline'
                                                    value={data.timezone_for_deadline} onChange={handleChange}
                                                    required>
                                                    <option>Select</option>
                                                    {
                                                        TimeZone.map((item, key) => {
                                                            const { value, label } = item
                                                            return <option value={value} key={key}>{label}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className='mb-4' controlId="" label="Timezone For Session">
                                                <Form.Label>Timezone For Session</Form.Label>
                                                <Form.Select name='timezone_for_session'
                                                    value={data.timezone_for_session} onChange={handleChange}
                                                    required>
                                                    <option>Select</option>
                                                    {
                                                        TimeZone.map((item, key) => {
                                                            const { value, label } = item
                                                            return <option value={value} key={key}>{label}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className='mb-4' controlId="" label="Topic Focus">
                                                <Form.Label>Topic Focus</Form.Label>
                                                <Form.Control as="textarea" placeholder="Topic Focus" name='topic_focus'
                                                    value={data.topic_focus} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Banner">
                                                <Form.Label>Banner</Form.Label>
                                                <Form.Control type="file" placeholder="Banner" name='banner'
                                                    onChange={handleChangeFile} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Logo">
                                                <Form.Label>Logo</Form.Label>
                                                <Form.Control type="file" placeholder="Logo" name='logo'
                                                    onChange={handleChangeFile} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Event Web Page">
                                                <Form.Label>Event Web Page</Form.Label>
                                                <Form.Control type="text" placeholder="Event Web Page"
                                                    name='event_web_page' value={data.event_web_page}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Venue">
                                                <Form.Label>Venue</Form.Label>
                                                <Form.Control type="text" placeholder="Venue" name='venue'
                                                    value={data.venue} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="City">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control type="text" placeholder="City" name='city'
                                                    value={data.city} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="State">
                                                <Form.Label>State</Form.Label>
                                                <Form.Control type="text" placeholder="State" name='state'
                                                    value={data.state} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Country">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Select name='country' value={data.country} onChange={handleChange}
                                                    required>
                                                    <option>Select</option>
                                                    {
                                                        CountryList.map((item, key) => {
                                                            return <option value={item} key={key}>{item}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Timezone">
                                                <Form.Label>Timezone</Form.Label>
                                                <Form.Select name='timezone' value={data.timezone}
                                                    onChange={handleChange} required>
                                                    <option>Select</option>
                                                    {
                                                        TimeZone.map((item, key) => {
                                                            const { value, label } = item
                                                            return <option value={value} key={key}>{label}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Contact Email">
                                                <Form.Label>Contact Email</Form.Label>
                                                <Form.Control type="email" placeholder="Contact Email"
                                                    name='contact_email' value={data.contact_email}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId=""
                                                label="Event Organaization Phone">
                                                <Form.Label>Event Organaization Phone</Form.Label>
                                                <Form.Control type="text" placeholder="Event Organaization Phone"
                                                    name='event_org_phone' value={data.event_org_phone}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Nature Of Submission">
                                                <Form.Label>Nature Of Submission</Form.Label>
                                                <Form.Control type="text" placeholder="Nature Of Submission"
                                                    name='nature_of_submission'
                                                    value={data.nature_of_submission}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Pre Submission Abstract Allowed </label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="pre_submission_abstract_allowed1"
                                                    name="pre_submission_abstract_allowed"
                                                    onChange={handleChange}
                                                    defaultChecked={data.pre_submission_abstract_allowed === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="pre_submission_abstract_allowed2"
                                                    name="pre_submission_abstract_allowed"
                                                    onChange={handleChange}
                                                    defaultChecked={data.pre_submission_abstract_allowed === 0 ? true : false} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Submission Topics">
                                                <Form.Label></Form.Label>
                                                <Form.Control type="text" placeholder="Submission Topics"
                                                    name='submission_topics' value={data.submission_topics}
                                                    onChange={handleChange} />

                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Disable Abstract Submission Form</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="disable_abstract_submission_form1"
                                                    name="disable_abstract_submission_form"
                                                    onChange={handleChange}
                                                    defaultChecked={data.disable_abstract_submission_form === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="disable_abstract_submission_form2"
                                                    name="disable_abstract_submission_form"
                                                    onChange={handleChange}
                                                    defaultChecked={data.disable_abstract_submission_form === 0 ? true : false} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Disable Multiple Authors</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="disable_multiple_authors1"
                                                    name="disable_multiple_authors" onChange={handleChange}
                                                    defaultChecked={data.disable_multiple_authors === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="disable_multiple_authors2"
                                                    name="disable_multiple_authors" onChange={handleChange}
                                                    defaultChecked={data.disable_multiple_authors === 0 ? true : false} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId=""
                                                label="Text Abstracts Max Length">
                                                <Form.Label>Text Abstracts Max Length</Form.Label>
                                                <Form.Control type="text" placeholder="Text Abstracts Max Length"
                                                    name='text_abstracts_max_length'
                                                    value={data.text_abstracts_max_length}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId=""
                                                label="Submission Instructions For Authors">
                                                <Form.Label>Submission Instructions For Authors</Form.Label>
                                                <Form.Control type="text"
                                                    placeholder="Submission Instructions For Authors"
                                                    name='submission_instructions_for_authors'
                                                    value={data.submission_instructions_for_authors}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId=""
                                                label="Submission Templates For Authors">
                                                <Form.Label>Submission Templates For Authors</Form.Label>
                                                <Form.Control type="text" placeholder="Submission Templates For Authors"
                                                    name='submission_templates_for_authors'
                                                    value={data.submission_templates_for_authors}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId=""
                                                label="Additional Fields Submission Form">
                                                <Form.Label>Additional Fields Submission Form</Form.Label>
                                                <Form.Control type="text"
                                                    placeholder="Additional Fields Submission Form"
                                                    name='additional_fields_submission_form'
                                                    value={data.additional_fields_submission_form}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId=""
                                                label="File Upload Fields Submission Form">
                                                <Form.Label>File Upload Fields Submission Form</Form.Label>
                                                <Form.Control type="text"
                                                    placeholder="File Upload Fields Submission Form"
                                                    name='file_upload_fields_submission_form'
                                                    value={data.file_upload_fields_submission_form}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Author Selecting Presenter</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="author_selecting_presenter1"
                                                    name="author_selecting_presenter" onChange={handleChange}
                                                    defaultChecked={data.author_selecting_presenter === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="author_selecting_presenter2"
                                                    name="author_selecting_presenter" onChange={handleChange}
                                                    defaultChecked={data.author_selecting_presenter === 0 ? true : false} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>New Submission Allowed</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="new_submission_allowed1" name="new_submission_allowed"
                                                    onChange={handleChange}
                                                    defaultChecked={data.new_submission_allowed === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="new_submission_allowed2" name="new_submission_allowed"
                                                    onChange={handleChange}
                                                    defaultChecked={data.new_submission_allowed === 0 ? true : false} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Submission Update Allowed</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="submission_update_allowed1"
                                                    name="submission_update_allowed" onChange={handleChange}
                                                    defaultChecked={data.submission_update_allowed === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="submission_update_allowed2"
                                                    name="submission_update_allowed" onChange={handleChange}
                                                    defaultChecked={data.submission_update_allowed === 0 ? true : false} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Automatic Submission Page Opening or
                                                    Closure</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="automatic_submission_page_opening_or_closure1"
                                                    name="automatic_submission_page_opening_or_closure"
                                                    onChange={handleChange}
                                                    defaultChecked={data.automatic_submission_page_opening_or_closure === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="automatic_submission_page_opening_or_closure2"
                                                    name="automatic_submission_page_opening_or_closure"
                                                    onChange={handleChange}
                                                    defaultChecked={data.automatic_submission_page_opening_or_closure === 1 ? true : false} />
                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Is Paper Binding Enabled</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="is_paper_binding_enabled1"
                                                    name="is_paper_binding_enabled" onChange={handleChange}
                                                    defaultChecked={data.is_paper_binding_enabled === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="is_paper_binding_enabled2"
                                                    name="is_paper_binding_enabled" onChange={handleChange}
                                                    defaultChecked={data.is_paper_binding_enabled === 0 ? true : false} />
                                            </Form.Group>
                                        </Col> */}
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Is Viewing Bids of PC Members Chairs
                                                    Enabled</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="is_viewing_bids_of_pc_members_chairs_enabled1"
                                                    name="is_viewing_bids_of_pc_members_chairs_enabled"
                                                    onChange={handleChange}
                                                    defaultChecked={data.is_viewing_bids_of_pc_members_chairs_enabled === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="is_viewing_bids_of_pc_members_chairs_enabled2"
                                                    name="is_viewing_bids_of_pc_members_chairs_enabled"
                                                    onChange={handleChange}
                                                    defaultChecked={data.is_viewing_bids_of_pc_members_chairs_enabled === 0 ? true : false} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Is Assignment Submitted Papers to Program
                                                    Committee Enabled</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="is_assignment_submitted_papers_to_program_committee_enabled1"
                                                    name="is_assignment_submitted_papers_to_program_committee_enabled"
                                                    onChange={handleChange}
                                                    defaultChecked={data.is_assignment_submitted_papers_to_program_committee_enabled === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="is_assignment_submitted_papers_to_program_committee_enabled2"
                                                    name="is_assignment_submitted_papers_to_program_committee_enabled"
                                                    onChange={handleChange}
                                                    defaultChecked={data.is_assignment_submitted_papers_to_program_committee_enabled === 0 ? true : false} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth">
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId=""
                                                label="Number of Reviewers Per Paper">
                                                <Form.Label>Number of Reviewers Per Paper</Form.Label>
                                                <Form.Control type="text" placeholder="Number of Reviewers Per Paper"
                                                    name='number_of_reviewers_per_paper'
                                                    value={data.number_of_reviewers_per_paper}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Reviewing Model">
                                                <Form.Label>Reviewing Model</Form.Label>
                                                <Form.Control type="text" placeholder="Reviewing Model"
                                                    name='reviewing_model' value={data.reviewing_model}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Are Reviewier Names Visible to Program
                                                    Commmitte </label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="are_reviewier_names_visible_to_pc1"
                                                    name="are_reviewier_names_visible_to_pc"
                                                    onChange={handleChange}
                                                    defaultChecked={data.are_reviewier_names_visible_to_pc === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="are_reviewier_names_visible_to_pc2"
                                                    name="are_reviewier_names_visible_to_pc"
                                                    onChange={handleChange}
                                                    defaultChecked={data.are_reviewier_names_visible_to_pc === 0 ? true : false} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Start Menu is">
                                                <Form.Label>Start Menu is</Form.Label>
                                                <Form.Control type="text" placeholder="Start Menu is"
                                                    name='start_menu_is' value={data.start_menu_is}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Review Menu is">
                                                <Form.Label>Review Menu is</Form.Label>
                                                <Form.Control type="text" placeholder="Review Menu is"
                                                    name='review_menu_is' value={data.review_menu_is}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Permit Progrm Committe Members to Enter
                                                    Reviews</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="permit_pc_members_to_enter_reviews1"
                                                    name="permit_pc_members_to_enter_reviews"
                                                    onChange={handleChange}
                                                    defaultChecked={data.permit_pc_members_to_enter_reviews === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="permit_pc_members_to_enter_reviews2"
                                                    name="permit_pc_members_to_enter_reviews"
                                                    onChange={handleChange}
                                                    defaultChecked={data.permit_pc_members_to_enter_reviews === 0 ? true : false} />
                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Leading Reviewers can Update
                                                    Metareviews</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="leading_reviewers_can_update_metareviews1"
                                                    name="leading_reviewers_can_update_metareviews"
                                                    onChange={handleChange}
                                                    defaultChecked={data.leading_reviewers_can_update_metareviews === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="leading_reviewers_can_update_metareviews2"
                                                    name="leading_reviewers_can_update_metareviews"
                                                    onChange={handleChange}
                                                    defaultChecked={data.leading_reviewers_can_update_metareviews === 0 ? true : false} />
                                            </Form.Group>
                                        </Col> */}
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Access to Reviews by Ordinary Program Committe
                                                    Members</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="access_to_reviews_by_ordinary_pc_members1"
                                                    name="access_to_reviews_by_ordinary_pc_members"
                                                    onChange={handleChange}
                                                    defaultChecked={data.access_to_reviews_by_ordinary_pc_members === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="access_to_reviews_by_ordinary_pc_members2"
                                                    name="access_to_reviews_by_ordinary_pc_members"
                                                    onChange={handleChange}
                                                    defaultChecked={data.access_to_reviews_by_ordinary_pc_members === 0 ? true : false} />
                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Use Offline Review Forms</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="use_offline_review_forms1"
                                                    name="use_offline_review_forms" onChange={handleChange}
                                                    defaultChecked={data.use_offline_review_forms === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="use_offline_review_forms2"
                                                    name="use_offline_review_forms" onChange={handleChange}
                                                    defaultChecked={data.use_offline_review_forms === 0 ? true : false} />
                                            </Form.Group>
                                        </Col> */}
                                        {/* <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Review can have Attachments</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="review_can_have_attachments1"
                                                    name="review_can_have_attachments" onChange={handleChange}
                                                    defaultChecked={data.review_can_have_attachments === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="review_can_have_attachments2"
                                                    name="review_can_have_attachments" onChange={handleChange}
                                                    defaultChecked={data.review_can_have_attachments === 0 ? true : false} />
                                            </Form.Group>
                                        </Col> */}
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Review Form">
                                                <Form.Label>Review Form</Form.Label>
                                                <Form.Control type="text" placeholder="Review Form" name='review_form'
                                                    value={data.review_form} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Decisions">
                                                <Form.Label>Decisions</Form.Label>
                                                <Form.Control type="text" placeholder="Decisions" name='decisions'
                                                    value={data.decisions} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Reviewer">
                                                <Form.Label>Reviewer</Form.Label>
                                                <Form.Control type="text" placeholder="Reviewer" name='reviewer'
                                                    value={data.reviewer} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Instructions">
                                                <Form.Label>Instructions</Form.Label>
                                                <Form.Control type="text" placeholder="Instructions" name='instructions'
                                                    value={data.instructions} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Use Subviewers</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="use_subviewers1" name="use_subviewers"
                                                    onChange={handleChange}
                                                    defaultChecked={data.use_subviewers === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="use_subviewers2" name="use_subviewers"
                                                    onChange={handleChange}
                                                    defaultChecked={data.use_subviewers === 0 ? true : false} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Are Submissions Anonymour For
                                                    Subreviewers</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="are_submissions_anonymour_for_subreviewers1"
                                                    name="are_submissions_anonymour_for_subreviewers"
                                                    onChange={handleChange}
                                                    defaultChecked={data.are_submissions_anonymour_for_subreviewers === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="are_submissions_anonymour_for_subreviewers2"
                                                    name="are_submissions_anonymour_for_subreviewers"
                                                    onChange={handleChange}
                                                    defaultChecked={data.are_submissions_anonymour_for_subreviewers === 0 ? true : false} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Permit Subreviewers Participate In
                                                    Discussion</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="permit_subreviewers_participate_in_discussion1"
                                                    name="permit_subreviewers_participate_in_discussion"
                                                    onChange={handleChange}
                                                    defaultChecked={data.permit_subreviewers_participate_in_discussion === 1 ? true : false} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="permit_subreviewers_participate_in_discussion2"
                                                    name="permit_subreviewers_participate_in_discussion"
                                                    onChange={handleChange}
                                                    defaultChecked={data.permit_subreviewers_participate_in_discussion === 0 ? true : false} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4'>
                                                <label className='w-100'>Are Track Chairs Allowed Reviews to
                                                    Authors</label>
                                                <Form.Check inline type="radio" label="Yes" value='1'
                                                    id="are_track_chairs_allowed_reviews_to_authors1"
                                                    name="are_track_chairs_allowed_reviews_to_authors"
                                                    onChange={handleChange}
                                                    checked={parseInt(data.are_track_chairs_allowed_reviews_to_authors) === 1} />
                                                <Form.Check inline type="radio" label="No" value='0'
                                                    id="are_track_chairs_allowed_reviews_to_authors2"
                                                    name="are_track_chairs_allowed_reviews_to_authors"
                                                    onChange={handleChange}
                                                    checked={parseInt(data.are_track_chairs_allowed_reviews_to_authors) === 0} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fifth">
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Submission Web Page">
                                                <Form.Label>Submission Web Page</Form.Label>
                                                <Form.Control type="text" placeholder="Submission Web Page"
                                                    name='submission_web_page'
                                                    value={data.submission_web_page} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <label className='form-label' htmlFor="License Expiry Date">License Expiry Date</label>
                                            <SingleDatePicker
                                                block={true}
                                                date={licenseExpiryDate ? moment(licenseExpiryDate, 'YYYY-MM-DD') : null}
                                                onDateChange={handleLicenseExpiryDateChange}
                                                focused={focused}
                                                onFocusChange={({ focused }) => setFocused(focused)}
                                                id="license_expiry_date" // Give it a unique ID
                                                isOutsideRange={isOutsideRange} // Allow any date to be selected
                                                numberOfMonths={1} // Display one month at a time
                                                displayFormat="YYYY-MM-DD"
                                                readOnly={true}
                                                // customInputIcon={<FaCalendarAlt />}
                                                placeholder="Select date"
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Event Approval Email">
                                                <Form.Label>Event Approval Email</Form.Label>
                                                <Form.Control type="email" placeholder="Event Approval Email"
                                                    name='event_approval_email'
                                                    value={data.event_approval_email}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="Event Support Emails">
                                                <Form.Label>Event Support Emails</Form.Label>
                                                <Form.Control type="email" placeholder="Event Support Emails"
                                                    name='event_support_emails'
                                                    value={data.event_support_emails}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='mb-4' controlId="" label="">
                                                <Form.Label>Event Management Url</Form.Label>
                                                <Form.Control type="text" placeholder="Event Management Url"
                                                    name='event_management_url'
                                                    value={data.event_management_url}
                                                    onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </CardBody>
                    </Tab.Container>
                    <CardFooter className='text-end'>
                        <Button type='submit'>Submit</Button>
                    </CardFooter>
                </Form>
            </Card>
        </>
    )
}

export default EditConference