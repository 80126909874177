import React, {useEffect, useState} from 'react'
import BreadCrumb from '../../components/elements/BreadCrumb'
import {Form, Card, Modal, Table} from 'react-bootstrap'
import CountryList from '../../data/CountryList'
import useAjax from "../../useAjax";
import {useApplicationContext} from "../../contexts/ApplicationContext";

const baseURL = process.env.REACT_APP_ENDPOINT

const Profile = () => {

    let user_id = localStorage.getItem('id')

    const [getSingleUserDataCall, getSingleUserDataStatus] = useAjax('get');
    const [profileUpdateCall, profileUpdateCallStatus] = useAjax('post', `${baseURL}/users/profile-update`)
    const [, dispatchApplicationContext] = useApplicationContext();


    const [data, setData] = useState({
        "first_name": '',
        "last_name": '',
        "country": '',
        "affiliation": '',
    })

    const [showProfileEditModal, setShowProfileEditModal] = useState(false);

    const handleShowProfileEditModal = () => {
        setShowProfileEditModal(true)
    }
    const handleCloseProfileEditModal = () => setShowProfileEditModal(false)

    const handleChangeEdit = (e) => {
        let name = e.target.name
        let value = e.target.value

        setData((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }

    useEffect(() => {
        if (getSingleUserDataStatus?.result[0]) {
            setData(getSingleUserDataStatus?.result[0])
        }

    }, [getSingleUserDataStatus])

    const getSingleUserData = (id) => getSingleUserDataCall({URL: `${baseURL}/users/${id}`});

    useEffect(() => {
        getSingleUserData(user_id)
    }, [])

    useEffect(() => {
        getSingleUserData(user_id)
        handleCloseProfileEditModal()
        if(profileUpdateCallStatus?.message){
            dispatchApplicationContext({type: "serviceSuccess", payload: {statusMessage: profileUpdateCallStatus.message}})
        }

    }, [profileUpdateCallStatus]);

    const handleSubmitProfileEdit = (e) => {
        e.preventDefault();
        const confirmation = window.confirm("Are you sure to update the profile?")
        if (confirmation) {
            profileUpdateCall({payload: data});
        }
    }

    return (
        <>
            <BreadCrumb title='My Profile' from='User' to='Profile' back={true}/>
            <Card className='elevated'>
                <div className="card-body">
                    <h3>{data.first_name} {data.last_name}</h3>
                    <Table striped bordered hover>
                        <colgroup>
                            <col width={'150px'}/>
                            <col/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>Email</th>
                            <td>{data.email}</td>
                        </tr>
                        <tr>
                            <th>Country</th>
                            <td>{data.country}</td>
                        </tr>
                        <tr>
                            <th>Affiliation</th>
                            <td>{data.affiliation}</td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
                <div className="card-footer">
                    <button className='btn btn-primary elevated btn-classic mt-2'
                            onClick={() => handleShowProfileEditModal()} title='Edit'><span
                        className="material-icons">edit_note</span> Edit Profile
                    </button>
                </div>
            </Card>

            {/* Edit Profile */}
            <Modal show={showProfileEditModal} onHide={handleCloseProfileEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitProfileEdit}>
                        <div className='mb-3'>
                            <Form.Group className='mb-4' controlId="" label="First Name">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" placeholder="First Name" name='first_name'
                                              value={data.first_name} onChange={handleChangeEdit} required/>
                            </Form.Group>
                            <Form.Group className='mb-4' controlId="" label="Last Name">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Last Name" name='last_name'
                                              value={data.last_name} onChange={handleChangeEdit}/>
                            </Form.Group>
                            <Form.Group className='mb-4' controlId="" label="Country">
                                <Form.Label>Country</Form.Label>
                                <Form.Select name='country' value={data.country} onChange={handleChangeEdit} required>
                                    <option>Select</option>
                                    {
                                        CountryList.map((item, key) => {
                                            return <option value={item} key={key}>{item}</option>
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className='mb-4' controlId="" label="Affiliation">
                                <Form.Label>Affiliation</Form.Label>
                                <Form.Control type="text" placeholder="Affiliation" name='affiliation'
                                              value={data.affiliation} onChange={handleChangeEdit} required/>
                            </Form.Group>
                        </div>
                        <div className='text-end'>
                            <button className="btn btn-clear secondary ms-3" onClick={handleCloseProfileEditModal}
                                    type='reset'>Cancel
                            </button>
                            <button className="btn btn-clear primary" type='submit'>Submit</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Profile