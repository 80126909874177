import React, {useState} from 'react';

const sortOrders = {
    "ASC": "ASC",
    "DESC": "DESC"
}

const sortingIcons = {
    'ASC': 'arrow_drop_down',
    'DESC': 'arrow_drop_up',
}

const DynamicTableHeader = ({headers = [], activeHeadKey = null, onHeaderClick = null}) => {
    const [activeHead, setActiveHead] = useState(activeHeadKey);
    const [sortOrder, setSortOrder] = useState(sortOrders.ASC);

    // update the sorting ahead of making any data changes in the table,
    // fallback would be to sort it if it fails on the background.
    const onSortHeaderClick = (headerKey) => {
        let newSortOrder = sortOrders.ASC;

        //if a new header is clicked, then reset the order
        if (headerKey !== activeHead) {
            newSortOrder = sortOrders.ASC;
        } else if (sortOrder === sortOrders.ASC) {
            newSortOrder = sortOrders.DESC;
        } else if (sortOrder === sortOrders.DESC) {
            newSortOrder = sortOrders.ASC;
        }

        setSortOrder(newSortOrder)
        setActiveHead(headerKey);


        if(onHeaderClick){
            onHeaderClick(headerKey, newSortOrder);
        }

    }

    return (
        <thead>
        <tr>
            {
                headers.map((item) => {
                    const {label, key, sortable} = item;
                    const isActiveHeader = key === activeHead;

                    return (
                        sortable ? <th onClick={() => onSortHeaderClick(key)}
                                       className={`sorting ${isActiveHeader ? 'active' : ''}`} key={key}>
                                {label}
                                {
                                    isActiveHeader && (
                                        <span className="material-icons">{sortingIcons[sortOrder]}</span>
                                    )
                                }
                            </th> :
                            <th key={key}>{label}</th>
                    )
                })
            }
        </tr>
        </thead>
    );
};

export default DynamicTableHeader;