import {useReducer, useContext, createContext} from "react";
import getPermissions from "../permissionMap";

const StateContext = createContext();
const DispatchContext = createContext();


const initialUserState = {
    permissions : ["samplePermission"],
    role: '',

}

const userContextReducer = (state, action) => {
    switch (action.type) {
        case 'onUserLogin': {
            const userRole = action.payload.role;
            const permissionsList = getPermissions(userRole);
            return {...state, permissions: permissionsList, role:userRole  };
        }
    }
};


export const UserContext = ({ children, initialState = initialUserState }) => {
    const [state, dispatch] = useReducer(userContextReducer, initialState  );

    return (
        <DispatchContext.Provider value={dispatch}>
            <StateContext.Provider value={state}>{children}</StateContext.Provider>
        </DispatchContext.Provider>
    );
};

export const useUserContext = () => [useContext(StateContext), useContext(DispatchContext)];

