import styles from './Loader.module.css';
const Downloading = () => {
    return (
        <div className={styles.downloading}>
            <h1>Loading...
                <br />
            Please Wait.</h1>
        </div>
    )
}

export default Downloading;