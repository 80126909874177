import BreadCrumb from '../../components/elements/BreadCrumb';
import { Card, CardBody, Dropdown, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
import { useUserContext } from "../../contexts/UserContext";
import Loader from "../../components/elements/loader/Loader";
import useAjax from "../../useAjax";
import { useApplicationContext } from "../../contexts/ApplicationContext";

const baseURL = process.env.REACT_APP_ENDPOINT

const AllConference = () => {
    const [data, setData] = useState([])
    const [tpcInviteData, setTpcInviteData] = useState('')
    let tokenStr = localStorage.getItem('access_token')
    let currentRole = localStorage.getItem('role');
    let currentID = parseInt(localStorage.getItem('id'))

    const [, dispatchApplicationContext] = useApplicationContext();

    const [userContext] = useUserContext();

    const [getConferenceCall, conferenceData] = useAjax('get', `${baseURL}/conference`);
    const [approveConfCall, approveConfCallSuccess] = useAjax('post');
    const [rejectConfCall, rejectConfCallSuccess] = useAjax('post');
    const [blockConfCall, blockConfCallSuccess] = useAjax('post');
    const [unBlockConfCall, unBlockConfCallSuccess] = useAjax('post');

    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })

    const navigate = useNavigate()

    useEffect(() => {
        if (conferenceData) {
            setData(conferenceData?.result);
        }
    }, [conferenceData])

    useEffect(() => {
        getConferenceCall();
    }, [])

    const alertSuccessMsg = (msg = '') => {
        dispatchApplicationContext({ type: 'serviceSuccess', payload: { statusMessage: msg } })
        getConferenceCall();
    }

    useEffect(() => {
        if (approveConfCallSuccess) {
            alertSuccessMsg(approveConfCallSuccess?.message);
        }
    }, [approveConfCallSuccess]);

    useEffect(() => {
        if (rejectConfCallSuccess) {
            alertSuccessMsg(rejectConfCallSuccess?.message);
        }
    }, [rejectConfCallSuccess]);

    useEffect(() => {
        if (blockConfCallSuccess) {
            alertSuccessMsg(blockConfCallSuccess?.message);
        }
    }, [blockConfCallSuccess]);

    useEffect(() => {
        if (unBlockConfCallSuccess) {
            alertSuccessMsg(unBlockConfCallSuccess?.message);
        }
    }, [unBlockConfCallSuccess]);


    const changeStatus = (id, stat) => {
        if (stat === 1) {
            const confirmation = window.confirm("Do you want to Approve this Conference?")
            if (confirmation) {
                approveConfCall({ URL: `${baseURL}/conference/approve/${id}` });
            }
        } else if (stat === 2) {
            const confirmation = window.confirm("Do you want to Reject this Conference?")
            if (confirmation) {
                rejectConfCall({ URL: `${baseURL}/conference/reject/${id}` });
            }
        } else if (stat === 3) {
            const confirmation = window.confirm("Do you want to Block this Conference?")
            if (confirmation) {
                blockConfCall({ URL: `${baseURL}/conference/block/${id}` });
            }
        } else if (stat === 4) {
            const confirmation = window.confirm("Do you want to UnbBlock this Conference?")
            if (confirmation) {
                unBlockConfCall({ URL: `${baseURL}/conference/unblock/${id}` });
            }
        }
    }

    const [trcRevAcceptDate, setTrcRevAcceptDate] = useState([]);
    const [showReviewerAcceptanceModal, setShowReviewerAcceptanceModal] = useState(false);
    const handleCloseReviewerAcceptanceModal = () => setShowReviewerAcceptanceModal(false);
    const handleShowReviewerAcceptanceModal = (data) => {
        setTrcRevAcceptDate(data)
        setShowReviewerAcceptanceModal(true)
    };


    //Send bulk mail
    const [generalMailData, setGeneralMailData] = useState({
        'start_sequence': '',
        'end_sequence': '',
        'sender_email': '',
        'subject': '',
        'message': '',
        // 'mail_list': '',
    })
    const [showSendMailModal, setShowSendMailModal] = useState(false);
    const handleCloseSendMailModal = () => setShowSendMailModal(false);
    const handleShowSendMailModal = () => {
        setShowSendMailModal(true)
    };
    // const [mailCount, setMailCount] = useState(0)
    const handelGeneralMailVal = (e) => {
        const name = e.target.name
        const value = e.target.value

        setGeneralMailData((prev) => {
            return ({ ...prev, [name]: value })
        })

        // if (generalMailData.mail_list.trim() !== "") {
        //     setMailCount(generalMailData.mail_list.split(",").length)
        // } else {
        //     setMailCount(0)
        // }

    }
    const handelSendMail = (e) => {
        e.preventDefault()
        let formData = {
            'start_sequence': generalMailData.start_sequence,
            'end_sequence': generalMailData.end_sequence,
            'sender_email': `${generalMailData.sender_email}@easymeet.ws`,
            'subject': generalMailData.subject,
            'message': generalMailData.message,
        }
        Axios.post(`${baseURL}/cfp-circulation-email/`, formData, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                setAlertData({
                    'alertShow': true,
                    'alertVariant': 'success',
                    'alertMessage': res.data.message
                })

            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data
                    })
                }
            )
    }



    const [showTpcInviteModal, setShowTpcInviteModal] = useState(false);
    const handleCloseTpcInviteModal = () => setShowTpcInviteModal(false);
    const handleShowTpcInviteModal = () => {
        setShowTpcInviteModal(true)
    };
    const handelSubmitInvitation = (e) => {
        e.preventDefault()
        let formData = {
            total_data: tpcInviteData
        }
        Axios.post(`${baseURL}/track/tpc-invitation`, formData, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                setAlertData({
                    'alertShow': true,
                    'alertVariant': 'success',
                    'alertMessage': res.data.message
                })
                setTpcInviteData('')
                setShowTpcInviteModal(false)

            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data
                    })
                }
            )
    }

    const manageAcceptance = (id, track_id, action) => {
        let formdata = {
            id: id,
            action: action
        }
        Axios.post(`${baseURL}/track/tpc-invitation-action/${track_id}`, formdata, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                setAlertData({
                    'alertShow': true,
                    'alertVariant': 'success',
                    'alertMessage': res.data.message
                })
                getConferenceCall();
                setShowReviewerAcceptanceModal(false)

            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data
                    })
                }
            )
    }

    useEffect(() => {
        if (alertData.alertShow) {
            setTimeout(() => {
                setAlertData({
                    'alertShow': false,
                    'alertVariant': '',
                    'alertMessage': null
                })
            }, 2000)
        }
    }, [alertData])


    const goToManageCfp = (id) => {
        navigate('/conference/cfp', { state: { id: id } })
    }
    const goToEditConference = (id) => {
        navigate('/conference/editConference', { state: { id: id } })
    }
    const goToConfigTracks = (id) => {
        navigate('/conference/tracks', { state: { id: id } })
    }
    const goToTPCMembers = (id) => {
        navigate('/conference/tracks/list/', { state: { id: id } })
    }
    const goToMyPapers = (id) => {
        navigate('/conference/mypapers/', { state: { id: id } })
    }
    const goToAllPapers = (id) => {
        navigate('/conference/papers/', { state: { id: id } })
    }
    const goToConferenceReports = (id) => {
        navigate('/conference/reports/', { state: { id: id } })
    }
    const goToReviewPapers = (id) => {
        navigate('/conference/reviewPapers/', { state: { id: id } })
    }


    return (
        <>

            <BreadCrumb title='All Conference' from='Conference' to='All Conference' back={true} />

            {
                data.length > 0 ?
                    data.map((item) => {
                        const {
                            id,
                            event_name,
                            acronym_title,
                            venue,
                            city,
                            state,
                            country,
                            logo,
                            logo_mime_type,
                            start_date,
                            end_date,
                            conference_status,
                            conf_id,
                            permissions,
                            oc_permissions,
                            tpc_invitation
                        } = item

                        let statusOptions = null
                        let statusLabel = null
                        // let statusClass = null
                        if (conference_status === 0) {
                            statusOptions = (<><Dropdown.Item
                                onClick={() => changeStatus(id, 1)}>Approve</Dropdown.Item><Dropdown.Item
                                    onClick={() => changeStatus(id, 2)}>Reject</Dropdown.Item><Dropdown.Item
                                        onClick={() => changeStatus(id, 3)}>Block</Dropdown.Item></>)
                            statusLabel = "Pending"
                            // statusClass = "secondary"
                        } else if (conference_status === 1) {
                            statusOptions = (<><Dropdown.Item
                                onClick={() => changeStatus(id, 3)}>Block</Dropdown.Item></>)
                            statusLabel = "Approved"
                            // statusClass = "primary"
                        } else if (conference_status === 2) {
                            statusOptions = (<></>)
                            statusLabel = "Rejected"
                            // statusClass = "danger"
                        } else if (conference_status === 3) {
                            statusOptions = (<><Dropdown.Item
                                onClick={() => changeStatus(id, 4)}>Unblock</Dropdown.Item></>)
                            statusLabel = "Blocked"
                            // statusClass = "warning"
                        }

                        let gcpcChecker = oc_permissions.includes(currentID)

                        return (
                            <Card className='mb-3 elevated' key={conf_id}>
                                <CardBody>
                                    <div className="row">
                                        <div className="" style={{ width: '100px' }}>
                                            {
                                                logo ? <img src={`data:${logo_mime_type};base64,${logo}`}
                                                    className='card-logo thumbnail' alt='Logo' /> : <div
                                                        className='defaultLogo thumbnail elevated'>{acronym_title}</div>
                                            }
                                        </div>
                                        <div className="col">
                                            <Card.Title>{event_name}</Card.Title>
                                            <h3>{acronym_title}</h3>
                                            <address>
                                                <span className='d-block'>{venue}</span>
                                                <span className='d-block'>{city}, {state}</span>
                                                <span className='d-block'>{country}</span>
                                            </address>
                                            <span
                                                className="material-icons size-16">today</span> {start_date} To {end_date}
                                            <Link className='btn btn-clear btn-sm ml-2 me-2 ms-2'
                                                to={`/conference/${id}`} title='Details'><span
                                                    className="material-icons">open_in_new</span> Details</Link>
                                            <Link className='btn btn-clear btn-sm me-2 '
                                                to={`/cfp/${conf_id}`} title='Visit CFP' target='_blank'><span
                                                    className="material-icons">open_in_new</span> View CFP</Link>
                                        </div>
                                        <div className="border-start" style={{ width: '320px' }}>
                                            {
                                                conference_status === 1 ? <>
                                                    {
                                                        permissions.includes("SUPERCHAIR") || currentRole === 'ADMIN' ? <>
                                                            {/* <Link
                                                                className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                                to={`/conference/cfp/${id}`} title='Manage CFP'><span
                                                                    className="material-icons">post_add</span> Manage
                                                                CFP</Link><Link
                                                                    className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                                    to={`/conference/editConference/${id}`}
                                                                    title='Edit Conference'><span
                                                                        className="material-icons">edit_note</span> Configuration</Link> */}
                                                            <button
                                                                className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                                onClick={() => goToManageCfp(id)} title='Manage CFP'><span
                                                                    className="material-icons">post_add</span> Manage
                                                                CFP</button><button
                                                                    className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                                    // to={`/conference/editConference/${id}`}
                                                                    onClick={() => goToEditConference(id)}
                                                                    title='Edit Conference'><span
                                                                        className="material-icons">edit_note</span> Configuration</button></> : ''
                                                    }
                                                    {
                                                        permissions.includes("SUPERCHAIR") || currentRole === 'ADMIN' || gcpcChecker === true ? <>
                                                            {/* <Link
                                                                className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                                to={`/conference/tracks/${id}`} title='Manage Tracks'><span
                                                                    className="material-icons">edit_road</span> Config
                                                                Tracks</Link></> : '' */}
                                                            <button
                                                                className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                                // to={`/conference/tracks/${id}`} 
                                                                onClick={() => goToConfigTracks(id)}
                                                                title='Manage Tracks'><span
                                                                    className="material-icons">edit_road</span> Config
                                                                Tracks</button></> : ''
                                                    }
                                                    {
                                                        permissions.includes("SUPERCHAIR") || permissions.includes("TRACKCHAIR") || currentRole === 'ADMIN' || gcpcChecker === true ? <>
                                                            {/* <Link
                                                                className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                                to={`/conference/tracks/list/${id}`}
                                                                title='Tracks'><span
                                                                    className="material-icons">edit_road</span> TPC
                                                                Members</Link></> : '' */}
                                                            <button
                                                                className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                                // to={`/conference/tracks/list/${id}`}
                                                                onClick={() => goToTPCMembers(id)}
                                                                title='Tracks'><span
                                                                    className="material-icons">edit_road</span> TPC
                                                                Members</button></> : ''
                                                    }

                                                    {/* <Link
                                                        className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                        to={`/conference/mypapers/${id}`} title='My Papers'><span
                                                            className="material-icons">article</span> My
                                                        Papers</Link> */}
                                                    <button
                                                        className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                        // to={`/conference/mypapers/${id}`} 
                                                        onClick={() => goToMyPapers(id)}
                                                        title='My Papers'><span
                                                            className="material-icons">article</span> My
                                                        Papers</button>
                                                    {
                                                        permissions.includes("SUPERCHAIR") || currentRole === 'ADMIN' || gcpcChecker === true ?
                                                            // <Link
                                                            //     className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                            //     to={`/conference/papers/${id}`} title='All Papers'><span
                                                            //         className="material-icons">article</span> All
                                                            //     Papers</Link>
                                                            // : ''
                                                            <>
                                                                <button
                                                                    className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                                    // to={`/conference/papers/${id}`} 
                                                                    onClick={() => goToAllPapers(id)}
                                                                    title='All Papers'><span
                                                                        className="material-icons">article</span> All
                                                                    Papers</button>
                                                                <button
                                                                    className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                                    // to={`/conference/papers/${id}`} 
                                                                    onClick={() => goToConferenceReports(id)}
                                                                    title='Reports'><span
                                                                        className="material-icons">report</span> Reports</button>
                                                            </>
                                                            : ''
                                                    }

                                                    {
                                                        tpc_invitation.length > 0 ?
                                                            <button className='btn btn-warning elevated btn-sm me-2 mb-2'
                                                                title='Review Acceptance' onClick={() => handleShowReviewerAcceptanceModal(tpc_invitation)}
                                                            ><span
                                                                className="material-icons ">fact_check</span> Accept TPC Invitation
                                                            </button>
                                                            : ''
                                                    }
                                                    {/* {
                                                        permissions.includes("SUPERCHAIR") || permissions.includes("REVIEWER") || currentRole === 'ADMIN' || gcpcChecker === true ? <>
                                                            <Link
                                                                className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                                to={`/conference/reviewPapers/${id}`} title='Review Paper'><span
                                                                    className="material-icons">open_in_new</span> Review Paper</Link>
                                                        </> : ''

                                                    } */}
                                                    {
                                                        permissions.includes("SUPERCHAIR") || permissions.includes("REVIEWER") || currentRole === 'ADMIN' || gcpcChecker === true ? <>
                                                            <button
                                                                className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                                // to={`/conference/reviewPapers/${id}`} 
                                                                onClick={() => goToReviewPapers(id)}
                                                                title='Review Paper'><span
                                                                    className="material-icons">open_in_new</span> Review Paper</button>
                                                        </> : ''

                                                    }

                                                    {/* {
                                                        currentRole === 'ADMIN' ?
                                                            <button className='btn btn-light elevated btn-sm me-2 mb-2'
                                                                title='TPC Invitation' onClick={() => handleShowTpcInviteModal()}
                                                            ><span className="material-icons ">forward_to_inbox</span> TPC Invitation</button>
                                                            : ''
                                                    } */}
                                                    {/* {
                                                        currentRole === 'ADMIN' ?
                                                            <button className='btn btn-classic btn-light elevated btn-sm me-2 mb-2'
                                                                title='Mail Send' onClick={() => handleShowSendMailModal(id)}
                                                            ><span className="material-icons">mail_outline</span> CFP Circulation</button>
                                                            : ''
                                                    } */}
                                                </> : ''
                                            }
                                            {
                                                permissions.includes("SUPERCHAIR") || currentRole === 'ADMIN' ?

                                                    currentRole === 'ADMIN' ?
                                                        <Dropdown className='d-inline-block align-top'>
                                                            <Dropdown.Toggle variant='light'
                                                                className='btn-classic btn-sm fw-8 elevated'>{statusLabel}</Dropdown.Toggle>
                                                            <Dropdown.Menu>{statusOptions}</Dropdown.Menu>
                                                        </Dropdown>
                                                        : <button
                                                            className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'>{statusLabel}</button>
                                                    : ''

                                            }
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        )
                    })
                    : (<Loader />)
                // <div className='card bg-fill-light-redish'><div className='card-body'><span className='material-icons text-danger'>priority_high</span> No Conference is submitted yet.</div></div>
            }

            <Modal Modal show={showReviewerAcceptanceModal} onHide={handleCloseReviewerAcceptanceModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Accept TPC Invitation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12" style={{ minHeight: '20rem' }}>
                            <table className='table'>
                                <colgroup>
                                    <col width={'70%'} />
                                    <col width={'30%'} />
                                </colgroup>
                                <tbody>
                                    {
                                        trcRevAcceptDate.map((item) => {
                                            const { id, track_id, track_name } = item
                                            return (
                                                <tr>
                                                    <td>{track_name}</td>
                                                    <td>
                                                        <button className='btn btn-sm' onClick={() => manageAcceptance(id, track_id, "ACCEPT")}><span className="material-icons">check</span> Accept</button>
                                                        <button className='btn btn-sm' onClick={() => manageAcceptance(id, track_id, "REJECT")}><span className="material-icons ">cancel</span>Reject</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal Modal show={showTpcInviteModal} onHide={handleCloseTpcInviteModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>TPC Invitation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handelSubmitInvitation}>
                        <div className='mb-3'>
                            <label>Enter member list. <small>JSON format only</small></label>
                            <textarea className='form-control' rows={12} name="total_data" value={tpcInviteData} onChange={(e) => setTpcInviteData(e.target.value)} required></textarea>
                        </div>
                        <input type="submit" value="Submit" className='btn btn-primary' />
                    </form>
                </Modal.Body>
            </Modal>
            <Modal Modal show={showSendMailModal} onHide={handleCloseSendMailModal} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>CFP Circulation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handelSendMail}>
                        <div className="row">
                            <div className='col-md-6'>
                                <div className="row">
                                    <div className='col-md-3'>
                                        <div className='mb-3'>
                                            <label>Start Sequence</label>
                                            <input type='number' className='form-control' name="start_sequence" value={generalMailData.start_sequence} onChange={handelGeneralMailVal} required />
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='mb-3'>
                                            <label>End Sequence</label>
                                            <input type='number' className='form-control' name="end_sequence" value={generalMailData.end_sequence} onChange={handelGeneralMailVal} required />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-md-6'>
                                        <div className='mb-3'>
                                            <label>Sender Email</label>
                                            <div class="input-group-text-with-bg input-group">
                                                <input type='text' className='form-control' name="sender_email" value={generalMailData.sender_email} onChange={handelGeneralMailVal} required />
                                                <div class="input-group-text" id="basic-addon2">@easymeet.ws</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label>Subject</label>
                                    <input type='text' className='form-control' name="subject" value={generalMailData.subject} onChange={handelGeneralMailVal} required />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label>CFP Message Body</label>
                                    <textarea className='form-control' rows={7} name="message" value={generalMailData.message} onChange={handelGeneralMailVal} required></textarea>
                                </div>
                            </div>
                            {/* <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label>Mail List <small className='text-secondary'>(Comma separated)</small></label>
                                    <textarea className='form-control' rows={12} name="mail_list" value={generalMailData.mail_list} onChange={handelGeneralMailVal} required></textarea>
                                    <p>{mailCount}</p>
                                </div>
                            </div> */}
                        </div>
                        <input type="submit" value="Submit" className='btn btn-primary' />
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AllConference