import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/static/css/App.scss';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import {UserContext} from "./contexts/UserContext";
import ApplicationContext from "./contexts/ApplicationContext";
import ErrorHandler from "./components/essentials/errorHandler/ErrorHandler";
import SuccessHandler from "./components/essentials/SuccessHandler/SuccessHandler";

function App() {

  useEffect(() => {
    // document.title = "Conference"
    document.title = process.env.REACT_APP_APP_NAME || "EasyMeet"
  }, [])

  return (
      <UserContext>

              <BrowserRouter>
                  <ApplicationContext>
                      <ErrorHandler />
                      <SuccessHandler />
                      <AppRoutes />
                  </ApplicationContext>
              </BrowserRouter>

      </UserContext>
  );
}

export default App;
