import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Pagination = (props) => {
    const paginationCount = props.data
    const paginate = props.callback

    const [paginationOptions, setPaginationOptions] = useState({
        styleClass: 'pagination',
        labelLatest: 'New',
        labelOldest: 'Old',
        labelPrevious: 'Prev',
        labelNext: 'Next'
    })

    useEffect(() => {
        setPaginationOptions((prev) => {
            return {
                ...prev, ...props.options
            }
        })
    }, [props.options])


    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [selectedValue, setSelectedValue] = useState(1);
    const [prevNumber, setPrevNumber] = useState()
    const [nextNumber, setNextNumber] = useState()
    const [active, setActive] = useState(1)
    const activeNumber = (currentNumber) => {
        setPrevNumber(() => currentNumber > 0 ? currentNumber - 1 : '')
        setNextNumber(() => currentNumber < paginationCount ? currentNumber + 1 : '')
        setActive(currentNumber)
    }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    const selectGoToOptions = [];
    const output = [];
    for (let i = 1; i <= paginationCount; i++) {
        const isActive = i === active;
        output.push(<li className='page-item'><Link key={i.toString()} className={isActive ? "page-link active" : "page-link"} onClick={() => {
            paginate(i)
            activeNumber(i)
        }}>{i}</Link></li>);
        selectGoToOptions.push(<option value={i}>{i.toString()}</option>)
    }

    const handelChange = async (event) => {
        const value = event.target.value;
        setSelectedValue(value);

        if (value) {
            paginate(value)
            activeNumber(value)
        }
    }
    const SelectGoTo = () => {
        return (
            <select value={selectedValue} onChange={handelChange} className="ms-2" style={{"width":"50px"}}>
                {selectGoToOptions}
            </select>
        )
    }
    if (output.length > 10 || screenWidth < 1024) {
        return (
            <>
                <div>Go To <SelectGoTo /></div>
            </>
        )
    } else {
        return (
            <ul className={paginationOptions.styleClass}>
                {
                    paginationOptions.labelLatest !== '' ? <li className='page-item'><Link className='page-link' onClick={() => { paginate(1); activeNumber(1) }}>{paginationOptions.labelLatest}</Link></li> : ''
                }
                {
                    paginationOptions.labelPrevious !== '' ? <li className='page-item'><Link className='page-link' onClick={() => { paginate(prevNumber); activeNumber(prevNumber) }}>{paginationOptions.labelPrevious}</Link></li> : ''
                }
                {output}
                {
                    paginationOptions.labelNext !== '' ? <li className='page-item'><Link className='page-link' onClick={() => { paginate(nextNumber); activeNumber(nextNumber) }}>{paginationOptions.labelNext}</Link></li> : ''
                }{
                    paginationOptions.labelOldest !== '' ? <li className='page-item'><Link className='page-link' onClick={() => { paginate(paginationCount); activeNumber(paginationCount) }}>{paginationOptions.labelOldest}</Link></li> : ''
                }
            </ul>
        )
    }
}

export default Pagination