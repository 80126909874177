const ReviewerConfidence = [
    {
        'value': 'Expert (5)',
        'label': 'Expert (5)',
    },
    {
        'value': 'High (4)',
        'label': 'High (4)',
    }, {
        'value': 'Medium (3)',
        'label': 'Medium (3)',
    }, {
        'value': 'Low (2)',
        'label': 'Low (2)',
    }, {
        'value': 'None (1)',
        'label': 'None (1)',
    }
]

export default ReviewerConfidence