import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import AlertCustom from "../../elements/AlertCustom";
import {useApplicationContext} from "../../../contexts/ApplicationContext";

const error401DataObj = {
    'alertShow': true,
    'alertVariant': 'danger',
    'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
}

const closedAlertData = {
    'alertShow': false,
    'alertVariant': null,
    'alertMessage': null
}

const getWarningMessage = (statusMessage) =>  {
    return {
        'alertShow': true,
        'alertVariant': 'warning',
        'alertMessage': statusMessage || 'No message defined'
    }
}


const ErrorHandler = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [alertData, setAlertData] = useState(closedAlertData);
    const [ApplicationContext, dispatchApplicationContext] = useApplicationContext();

    useEffect(() => {
        setError(ApplicationContext?.serviceError)
    }, [ApplicationContext?.serviceError])

    useEffect(() => {
        if(error?.statusCode === 601){
            navigate('/');
        }
        if (error?.statusCode === 401 ) {
            setAlertData(error401DataObj);
            setTimeout(()=>{
                dispatchApplicationContext({type:'serviceSuccess', payload: {} })

                navigate('/');
            }, 2000)
        }
        if(error?.statusCode === 199){
            setAlertData(getWarningMessage(error.statusMessage))
            setTimeout(() => {
                dispatchApplicationContext({type:'serviceSuccess', payload: {} })
            }, 2000)
        }
        else {
            setAlertData(closedAlertData);
        }
    }, [error])

    return (<AlertCustom data={alertData}/>)
}

export default ErrorHandler
