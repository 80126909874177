import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../components/elements/BreadCrumb'
import AlertCustom from '../../components/elements/AlertCustom'
import Axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Modal, Table } from 'react-bootstrap'
import Downloading from '../../components/elements/loader/Downloading'
import Pagination from '../../components/elements/Pagination'

const baseURL = process.env.REACT_APP_ENDPOINT
const tableOptions = {
    'styleClass': 'custom-datatable',
    'sortBy': 'paper_sequence',
    'sortingIconDown': 'arrow_drop_down',
    'sortingIconUp': 'arrow_drop_up',
    'searchBar': true,
    'searchBarPlaceholder': 'Search',
    'pageNo': 1,
    'perPage': true,
    'perPageLabel': 'Items per page',
    'perPageDefault': 500,
    'perPageOptions': [50, 100, 200, 500],
    'pagination': true,
    'paginationOptions': {
        'styleClass': 'pagination',
        'labelLatest': '',
        'iconLatest': 'New',
        'labelOldest': '',
        'iconOldest': 'Old',
        'labelPrevious': 'Prev',
        'iconPrevious': 'Prev',
        'iconNext': '',
        'labelNext': 'Next'
    }
}
const headers = [
    {
        'label': 'PaperID',
        'key': 'paper_sequence',
        'sortable': true
    }, {
        'label': 'Download',
        'key': '',
        'sortable': false
    }, {
        'label': 'Paper Title',
        'key': 'paper_title',
        'sortable': true
    }, {
        'label': 'Authors',
        'key': 'paper_author_data',
        'sortable': false
    }, {
        'label': 'Reviewers',
        'key': 'paper_reviewer_data',
        'sortable': false
    }, {
        'label': 'Action',
        'key': '',
        'sortable': false
    },
]

const TrackPapers = () => {
    const [isLoading, setIsLoading] = useState(false);

    //Define
    let tokenStr = localStorage.getItem('access_token')
    const navigate = useNavigate()
    const detailsId = useParams()
    const [data, setData] = useState({
        id: '',
        conf_id: '',
        event_name: '',
        acronym_title: '',
        start_date: '',
        end_date: ''
    })
    const [allPaper, setAlPaper] = useState([])
    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })

    const [showReviewerModal, setShowReviewerModal] = useState(false);
    const [singleTrackReviewer, setSingleTrackReviewer] = useState([])
    const [singlePaperReviewer, setSinglePaperReviewer] = useState([])
    const [paperId, setPaperId] = useState()

    const [searchText, setSearchText] = useState(null)
    const [paramSearch, setParamSearch] = useState('')
    const [paginationCount, setPaginationCount] = useState()
    const [totalCount, setTotalCount] = useState()
    const [pageNo, setPageNo] = useState(tableOptions.pageNo)
    const [perPage, setPerPage] = useState(tableOptions.perPageDefault)
    const [sortBy, setSortBy] = useState(tableOptions.sortBy)
    const [orderType, setOrderType] = useState('ASC')
    const [sortOrder, setSortOrder] = useState(tableOptions.sortingIconDown)
    const [sortActive, setSortActive] = useState('')


    //Action
    useEffect(() => {
        if (alertData.alertShow) {
            setTimeout(() => {
                setAlertData({
                    'alertShow': false,
                })
            }, 7000)
        }
    }, [alertData])

    useEffect(() => {
        Axios.get(`${baseURL}/site/conf/short/${detailsId.id}`)
            .then(res => {
                setData(res.data.result[0])
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )

        getTrackPapers()
        getSingleTrack(detailsId.trackid)
    }, [])

    const getTrackPapers = () => {
        Axios.get(`${baseURL}/paper/all/${detailsId.trackid}/1?order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(res => {
                setAlPaper(res.data.result.data)
                setTotalCount(res.data.result.total);
                setPaginationCount(() => {
                    return Math.ceil(res.data.result.total / perPage)
                })
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )
    }
    const getSingleTrackPapers = (paperId) => {
        Axios.get(`${baseURL}/paper/${paperId}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(res => {
                setSinglePaperReviewer(res.data.result.paper_reviewer_data)
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )
    }
    const getSingleTrack = (id) => {
        Axios.get(`${baseURL}/track/${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(res => {
                setSingleTrackReviewer(res.data.result.track_reviewer_data)
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )
    }

    const handleCloseReviewerModal = () => setShowReviewerModal(false);
    const handleShowReviewerModal = (id) => {
        setPaperId(id)
        getSingleTrackPapers(id)
        setShowReviewerModal(true)
    };

    const addReviewer = (paperId, reviewer_id) => {
        const confirmation = window.confirm("Are you sure to add this TPC Member as Reviewer?")
        if (confirmation) {
            Axios.post(`${baseURL}/paper/reviewer-assign/${paperId}`, { 'reviewer_id': reviewer_id }, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then((res) => {
                    getTrackPapers()
                    getSingleTrackPapers(paperId)
                    if (res.data.success === false) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': res.data.message
                        })
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'success',
                            'alertMessage': res.data.message
                        })
                    }

                })
                .catch(
                    error => {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': error.response.data
                        })
                    }
                )
        }
    }
    const deleteReveiewer = (paper_id, id) => {
        const confirmation = window.confirm("Are you sure to delete this Reviewer?")
        if (confirmation) {
            Axios.delete(`${baseURL}/paper/reviewer/${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then((res) => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })

                    getTrackPapers()
                    getSingleTrackPapers(paper_id)

                })
                .catch(
                    error => {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': error.response.data
                        })
                    }
                )
        }
    }

    //Datatabel
    const handelSorting = (key) => {
        setSortBy(key)
        setSortActive(key === sortActive ? null : key)
    }

    const selectedPagination = (pageNumber) => {
        setPageNo(pageNumber)
    }

    const handleChangeSearchText = (e) => {
        const searchTerm = e.target.value
        setSearchText(searchTerm)
        if (searchText != null) {
            setParamSearch(`param_search=${e.target.value}&`)
        }

        // if (searchTerm.length > 2) {
        //     getAllPaperCall({ URL: `${baseURL}/paper/all/${detailsId.id}/0?${paramSearch}order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}` })
        // } else if (searchTerm.length === 0) {
        //     getAllPaperCall({ URL: `${baseURL}/paper/all/${detailsId.id}/0?order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}` })
        // }
    }
    const downloadPaper = async (id) => {
        let tokenStr = localStorage.getItem('access_token')
        setIsLoading(true);
        Axios.get(`${baseURL}/paper/${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(
                (res) => {
                    setIsLoading(false);
                    const byteCharacters = atob(res.data.result.paper_file_data);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: res.data.result.paper_file_mime_type });
                    // Create a link element
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = res.data.result.paper_sequence;

                    // Append to the document, trigger click, and remove
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    // Release the object URL
                    window.URL.revokeObjectURL(link.href);
                }
            ).catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <AlertCustom data={alertData} />
            <BreadCrumb title={`All Papers - ${data.acronym_title}`} from='Conference' to='Track Papers' back={true} />
            {isLoading && <Downloading />}

            <div className='card elevated p-3'>
                <div className="d-flex justify-content-between pb-3 table-filter-control">
                    <div>
                        {/* <input type="search" name="table-global" placeholder={tableOptions.searchBarPlaceholder} onChange={handleChangeSearchText} /> */}
                    </div>
                    <div>
                        <select name="h" value={perPage} onChange={(e) => setPerPage(e.target.value)}>
                            {
                                tableOptions.perPageOptions.map((item) => {
                                    return (
                                        <option value={item}>{item}</option>
                                    )
                                })
                            }
                        </select>
                        <span className="ml-1">{tableOptions.perPageLabel}</span>
                    </div>
                </div>
                <Table striped bordered hover className='custom-datatable' style={{ fontSize: '12px' }}>
                    {/* <thead>
                        <tr className=''>
                            <th>Sequence</th>
                            <th>Download</th>
                            <th>Paper Title</th>
                            <th>Authors</th>
                            <th>Reviewers</th>
                            <th>Action</th>
                        </tr>
                    </thead> */}
                    <thead>
                        <tr>
                            {
                                headers.map((item) => {
                                    const { label, key, sortable } = item
                                    return (
                                        sortable ? <th onClick={() => handelSorting(key)} className={`sorting ${key === sortActive ? 'active' : ''}`}>
                                            <span>{label}</span>
                                            <span className="material-icons">
                                                {sortOrder}
                                            </span>
                                        </th> : <th >{label}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allPaper.length > 0 ?
                                allPaper.map((item) => {
                                    const { id, paper_title, paper_sequence, paper_author_data, paper_reviewer_data, paper_file_path, status } = item
                                    return (

                                        <tr>
                                            <td><span className='badge bg-primary'><small>{paper_sequence}</small></span></td>
                                            <td>
                                                {
                                                    paper_file_path ?
                                                        <button className='btn btn-primary btn-xs' onClick={() => downloadPaper(id)}><span
                                                            className="material-icons">file_download</span></button>
                                                        : 'Paper not uploaded'
                                                }
                                            </td>
                                            <td>{paper_title}</td>
                                            <td>
                                                <div className='maxh-7'>
                                                    {
                                                        paper_author_data.length > 0 ?
                                                            paper_author_data.map((item) => {
                                                                const { author_first_name, author_last_name, author_email } = item
                                                                return (
                                                                    <div className='mb-3'>{author_first_name} {author_last_name} <br />{author_email}</div>
                                                                )
                                                            })
                                                            : ''
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className='maxh-5'>
                                                    {
                                                        paper_reviewer_data.length > 0 ?
                                                            paper_reviewer_data.map((item) => {
                                                                const { first_name, last_name, email } = item
                                                                return (
                                                                    <div className='mb-2'>{first_name} {last_name} <br />{email}</div>
                                                                )
                                                            })
                                                            : ''
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    status === 30 ? 'Blocked' : status === 25 ? 'Withdrawn' : <button className='btn btn-light elevated btn-xs btn-classic mb-2 me-1' title='Manage Reviewers' onClick={() => handleShowReviewerModal(id)}><span className="material-icons text-warning">manage_accounts</span> Manage Reviewers</button>
                                                }

                                            </td>
                                        </tr>
                                    )
                                }) : ''
                            // <div className='card bg-fill-light-redish'><div className='card-body'><span class='material-icons text-danger'>priority_high</span> No paper is submitted yet.</div></div>
                        }
                    </tbody>
                </Table>
                <div className="d-flex justify-content-between">
                    {paginationCount > 1 && (
                        <Pagination data={paginationCount} callback={selectedPagination} options={tableOptions.paginationOptions} />
                    )}
                    <div>
                        Total <strong>{totalCount}</strong> records
                    </div>
                </div>
            </div>

            {/* Manage Reviewer */}
            <Modal Modal show={showReviewerModal} onHide={handleCloseReviewerModal} size='lg' >
                <Modal.Header closeButton>
                    <Modal.Title>Manage Reviewer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-6" style={{ minHeight: '20rem' }}>
                            <h3><strong>TPC Members</strong></h3>

                            {
                                singleTrackReviewer.map((item) => {
                                    const { reviewer_id, reviewer_first_name, reviewer_last_name, reviewer_email } = item
                                    return (
                                        <div className='d-flex justify-content-between py-1 px-3 listItem'>
                                            <div>
                                                <h5 className='mb-1'>{reviewer_first_name} {reviewer_last_name}</h5>
                                                <span><small>{reviewer_email}</small></span>
                                            </div>
                                            <div>

                                                <button className='btn btn-sm btn-classic btn-primary' onClick={() => addReviewer(paperId, reviewer_id)}><span className="material-icons">add</span> Add</button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-6 border-start">
                            {
                                <>
                                    <h3><strong>Added Reviewers</strong></h3>
                                    <ul className='list-unstyled'>
                                        {
                                            singlePaperReviewer.map((item) => {
                                                const { paper_id, id, first_name, last_name, email } = item
                                                return (
                                                    <div className='d-flex justify-content-between align-items-center mb-1 listItem'>
                                                        <div>
                                                            <h5 className='m-0'>{first_name} {last_name} / <span><small>{email}</small></span></h5>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-xs btn-classic btn-danger' onClick={() => deleteReveiewer(paper_id, id)}><span className="material-icons">delete_forever</span> Remove</button>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </ul>
                                </>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default TrackPapers