import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Card, Row } from 'react-bootstrap'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import PageNotFound from '../../components/essentials/PageNotFound'
import sampleBanner from '../../static/images/slider.png'
import ResearchArea from '../../data/ResearchArea'
import AlertCustom from '../../components/elements/AlertCustom'

const baseURL = process.env.REACT_APP_ENDPOINT

const SingleConference = () => {
    const [data, setData] = useState({})
    const detailsId = useParams()
    const navigate = useNavigate()
    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })
    let tokenStr = localStorage.getItem('access_token')

    useEffect(() => {
        Axios.get(`${baseURL}/conference/${detailsId.id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(res => {
                setData(res.data.result[0])
            })

            .catch(error => {
                setAlertData({
                    'alertShow': true,
                    'alertVariant': 'danger',
                    'alertMessage': error.response.data
                })
            })

        yesToOne()
    }, [])

    //0, 1 to Yes No
    const yesToOne = (val) => {
        if (parseInt(val) === 1) {
            return "Yes"
        } else if (parseInt(val) === 0) {
            return "No"
        } else {
            return ""
        }
    }

    //Primary / Secondary area Id To Value
    const areaIdToValue = (val) => {
        const matchedArea = ResearchArea.find(area => area.value === val);

        // Check if a matching object was found
        if (matchedArea) {
            return matchedArea.label;
        } else {
            return '';
        }
    }

    //Destructure the data
    const { event_name, acronym_title, event_web_page, logo, logo_mime_type, banner, banner_mime_type, city, venue, state, country, timezone, contact_email, event_org_phone, start_date, end_date, publisher, series, geographic_focus, language, date_format, timezone_for_deadline, timezone_for_session, topic_focus, nature_of_submission, pre_submission_abstract_allowed, submission_topics, disable_abstract_submission_form, disable_multiple_authors, text_abstracts_max_length, submission_instructions_for_authors, submission_templates_for_authors, additional_fields_submission_form, file_upload_fields_submission_form, author_selecting_presenter, new_submission_allowed, submission_update_allowed, automatic_submission_page_opening_or_closure, is_paper_binding_enabled, is_viewing_bids_of_pc_members_chairs_enabled, is_assignment_submitted_papers_to_program_committee_enabled, number_of_reviewers_per_paper, reviewing_model, are_reviewier_names_visible_to_pc, start_menu_is, review_menu_is, permit_pc_members_to_enter_reviews, leading_reviewers_can_update_metareviews, access_to_reviews_by_ordinary_pc_members, use_offline_review_forms, review_can_have_attachments, review_form, decisions, reviewer, instructions, use_subviewers, are_submissions_anonymour_for_subreviewers, permit_subreviewers_participate_in_discussion, are_track_chairs_allowed_reviews_to_authors, submission_web_page, license_expiry_date, event_approval_email, event_support_emails, event_management_url, estimated_no_of_submissions, primary_area_id, secondary_area_id, area_notes, event_organizer, event_org_web_page
    } = data

    return (
        <div className='detailspage'>
            <AlertCustom data={alertData} />
            <NavLink onClick={() => navigate(-1)} className='btn btn-sm btn-light border-0 float-top-right'><span className="material-icons">chevron_left</span> Back</NavLink>
            {
                banner ? <img src={`data:${banner_mime_type};base64,${banner}`} className='banner' alt='Banner' /> : <img src={sampleBanner} className='banner' alt='Banner' />
            }
            
            <Card className='details elevated bg-fill-light-gray'>
                <Card.Body>
                    <div className='d-flex border-bottom mb-3 pb-3'>
                        <div style={{ marginRight: '1rem' }}>
                            {
                                logo ? <img src={`data:${logo_mime_type};base64,${logo}`} className='card-logo thumbnail' alt='logo' /> : <div className='defaultLogo elevated'>{acronym_title}</div>
                            }
                        </div>
                        <div className='w-100'>
                            <h1>{event_name}</h1>
                            <h2>{acronym_title}</h2>
                            <p><strong>From</strong> : {start_date} - <strong>To</strong> : {end_date}</p>
                        </div>
                        <div className='border-start location'>
                            {venue}<br />
                            {city}<br />
                            {state}<br />
                            {country}
                        </div>
                    </div>
                    <div className="p-2">
                    <Row md={2}>

                        <div className='dataLabelValue'><div className='dataLabel'> Estimated No Of Submissions </div><div className='dataValue'> {estimated_no_of_submissions}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Primary Area</div><div className='dataValue'>  {areaIdToValue(primary_area_id)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Secondary Area</div><div className='dataValue'>  {areaIdToValue(secondary_area_id)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Area Notes</div><div className='dataValue'>  {area_notes}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Link </div><div className='dataValue'> {event_web_page}</div></div>
                        {/* <div className='dataLabelValue'><div className='dataLabel'> City </div><div className='dataValue'> {city}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Venue </div><div className='dataValue'> {venue}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> State </div><div className='dataValue'> {state}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Country </div><div className='dataValue'> {country}</div></div> */}
                        <div className='dataLabelValue'><div className='dataLabel'> Timezone</div><div className='dataValue'> {timezone}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Contact Email</div><div className='dataValue'> {contact_email}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Contact Phone</div><div className='dataValue'> {event_org_phone}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Event Organizer </div><div className='dataValue'> {event_organizer}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Organizer Webpage </div><div className='dataValue'> {event_org_web_page}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Publisher</div><div className='dataValue'> {publisher}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Series </div><div className='dataValue'> {series}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Geographic Focus </div><div className='dataValue'> {geographic_focus}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Language</div><div className='dataValue'> {language}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Date Format </div><div className='dataValue'> {date_format}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Timezone for Deadline </div><div className='dataValue'> {timezone_for_deadline}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Timezone for Session </div><div className='dataValue'> {timezone_for_session}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Topic Focus </div><div className='dataValue'> {topic_focus}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Nature of Submission </div><div className='dataValue'> {nature_of_submission}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Pre Submission Abstract Allowed </div><div className='dataValue'>  {yesToOne(pre_submission_abstract_allowed)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Submission Topics </div><div className='dataValue'> {submission_topics}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Disable Abstract Submission Form </div><div className='dataValue'> {yesToOne(disable_abstract_submission_form)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Disable Multiple Authors </div><div className='dataValue'> {yesToOne(disable_multiple_authors)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Text Abstracts Max Length </div><div className='dataValue'> {text_abstracts_max_length}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Submission Instructions for Authors </div><div className='dataValue'> {submission_instructions_for_authors}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Submission Templates for Authors </div><div className='dataValue'> {submission_templates_for_authors}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Additional Fields Submission Form </div><div className='dataValue'> {additional_fields_submission_form}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> File Upload Fields Submission Form </div><div className='dataValue'> {file_upload_fields_submission_form}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Author Selecting Presenter </div><div className='dataValue'> {yesToOne(author_selecting_presenter)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> New Submission Allowed </div><div className='dataValue'> {yesToOne(new_submission_allowed)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Submission Update Allowed </div><div className='dataValue'> {yesToOne(submission_update_allowed)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Automatic Submission Page Opening or Closure </div><div className='dataValue'> {yesToOne(automatic_submission_page_opening_or_closure)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Is Paper Binding Enabled </div><div className='dataValue'> {yesToOne(is_paper_binding_enabled)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Is Viewing Bids of PC Members Chairs Enabled </div><div className='dataValue'> {yesToOne(is_viewing_bids_of_pc_members_chairs_enabled)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> is Assignment Submitted Papers to Program Committee Enabled </div><div className='dataValue'> {yesToOne(is_assignment_submitted_papers_to_program_committee_enabled)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Number of Reviewers per Paper </div><div className='dataValue'> {number_of_reviewers_per_paper}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Reviewing Model </div><div className='dataValue'> {reviewing_model}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Are Reviewier Names Visible to PC </div><div className='dataValue'> {yesToOne(are_reviewier_names_visible_to_pc)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Start Menu is </div><div className='dataValue'> {start_menu_is}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Review Menu is </div><div className='dataValue'> {review_menu_is}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Permit PC Members to Enter Reviews </div><div className='dataValue'> {yesToOne(permit_pc_members_to_enter_reviews)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Leading Reviewers can Update Metareviews </div><div className='dataValue'> {yesToOne(leading_reviewers_can_update_metareviews)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Access to Reviews by Ordinary PC Members </div><div className='dataValue'> {yesToOne(access_to_reviews_by_ordinary_pc_members)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Use Offline Review Forms </div><div className='dataValue'> {yesToOne(use_offline_review_forms)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Review can have Attachments </div><div className='dataValue'> {yesToOne(review_can_have_attachments)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Review Form </div><div className='dataValue'> {review_form}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Decisions </div><div className='dataValue'> {decisions}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Reviewer </div><div className='dataValue'> {reviewer}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Instructions </div><div className='dataValue'> {instructions}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Use Subviewers </div><div className='dataValue'> {yesToOne(use_subviewers)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Are Submissions Anonymous for Subreviewers </div><div className='dataValue'> {yesToOne(are_submissions_anonymour_for_subreviewers)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Permit Subreviewers Participate in Discussion </div><div className='dataValue'> {yesToOne(permit_subreviewers_participate_in_discussion)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Are Track Chairs Allowed Reviews to Authors </div><div className='dataValue'> {yesToOne(are_track_chairs_allowed_reviews_to_authors)}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Submission Webpage </div><div className='dataValue'> {submission_web_page}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> License Expiry Date </div><div className='dataValue'> {license_expiry_date}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Event Approval Email </div><div className='dataValue'> {event_approval_email}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Event Support Emails </div><div className='dataValue'> {event_support_emails}</div></div>
                        <div className='dataLabelValue'><div className='dataLabel'> Event Management URL </div><div className='dataValue'> {event_management_url}</div></div>

                    </Row>
        </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default SingleConference