import { Route, Routes } from "react-router-dom"
import Login from "../pages/login/Login"
import PageNotFound from "../components/essentials/PageNotFound"
import Dashboard from "../pages/Dashboard"
import Protected from "./Protected"
import Admin from "../components/layouts/Admin/Admin"
import AddConference from "../pages/conference/AddConference"
import AllConference from "../pages/conference/AllConference"
import Signup from "../pages/login/Signup"
import ForgotPassword from "../pages/login/ForgotPassword"
import Profile from "../pages/user/Profile"
import ChangePassword from "../pages/user/ChangePassword"
import ManageUsers from "../pages/settings/ManageUsers"
import SingleConference from "../pages/conference/SingleConference"
import ManageUserGroup from "../pages/settings/ManageUserGroup"
import ManagePermission from "../pages/settings/ManagePermission"
import ManageGroupPermissions from "../pages/settings/ManageGroupPermissions"
import EditConference from "../pages/conference/EditConference"
import Tracks from "../pages/conference/Tracks"
import Cfp from "../pages/conference/Cfp"
import CfpView from "../pages/conference/CfpView"
import CfpList from "../pages/conference/CfpList"
import Papers from "../pages/conference/Papers"
import MyPapers from "../pages/conference/MyPapers"
import TracksList from "../pages/conference/TracksList"
import TrackPapers from "../pages/conference/TrackPapers"
import ReviewPapers from "../pages/conference/ReviewPapers"
import SetPassword from "../pages/login/SetPassword"
import Reports from "../pages/reports/Reports"
import PaperReports from "../pages/conference/PaperReports"

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/:params" element={<SetPassword />} />
            <Route path="/cfp/:id" element={<CfpView />} />
            <Route path="/cfp-list" element={<CfpList />} />
            <Route path="*" element={<PageNotFound />} />


            {/* <Route path="/" element={<Classic />} >
                <Route index element={<Home />} />
                <Route path="/about" element={<About />} />
            </Route> */}

            {/* <Route path="/dashboard" element={<Protected Component={Admin} />}>
                <Route index element={<Dashboard />} />
            </Route> */}
            <Route path="/conference" element={<Protected Component={Admin} />}>
                <Route index element={<AllConference />} />
                <Route path="/conference/allConference" element={<AllConference />} />
                <Route path="/conference/addConference" element={<AddConference />} />
                {/* <Route path="/conference/editConference/:id" element={<EditConference />} /> */}
                <Route path="/conference/editConference/" element={<EditConference />} />
                {/* <Route path="/conference/tracks/:id" element={<Tracks />} /> */}
                <Route path="/conference/tracks/" element={<Tracks />} />
                {/* <Route path="/conference/tracks/list/:id" element={<TracksList />} /> */}
                <Route path="/conference/tracks/list/" element={<TracksList />} />
                <Route path="/conference/trackPapers/:id/:trackid" element={<TrackPapers />} />
                {/* <Route path="/conference/mypapers/:id" element={<MyPapers />} /> */}
                <Route path="/conference/mypapers/" element={<MyPapers />} />
                {/* <Route path="/conference/papers/:id" element={<Papers />} /> */}
                <Route path="/conference/papers/" element={<Papers />} />
                <Route path="/conference/reports/" element={<PaperReports />} />
                {/* <Route path="/conference/reviewPapers/:id" element={<ReviewPapers />} /> */}
                <Route path="/conference/reviewPapers/" element={<ReviewPapers />} />
                {/* <Route path="/conference/cfp/:id" element={<Cfp />} /> */}
                <Route path="/conference/cfp/" element={<Cfp />} />
                <Route path="/conference/:id" element={<SingleConference />} />
            </Route>
            <Route path="/user" element={<Protected Component={Admin} />}>
                <Route path="/user/profile" element={<Profile />} />
                <Route path="/user/changePassword" element={<ChangePassword />} />
            </Route>
            <Route path="/settings" element={<Protected Component={Admin} />}>
                <Route path="/settings/manageUsers" element={<ManageUsers />} />
                <Route path="/settings/manageUserGroups" element={<ManageUserGroup />} />
                <Route path="/settings/managePermissions" element={<ManagePermission />} />
                <Route path="/settings/manageGroupPermissions" element={<ManageGroupPermissions />} />
            </Route>
            <Route path="/reports" element={<Protected Component={Admin} />}>
                <Route path="/reports/adminReport" element={<Reports />} />
            </Route>

        </Routes>
    )
}

export default AppRoutes