import { NavLink } from 'react-router-dom'
import { menuItems } from './Menu'
import { userMenuItems } from './MenuUser'
import SubNav from './SubNav'
import SubNavLabel from './SubNavLabel'
import { Modal } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import Axios from 'axios'
import AlertCustom from '../../../elements/AlertCustom'
const baseURL = process.env.REACT_APP_ENDPOINT

const Sidebar = () => {
    let currentRole = localStorage.getItem('role')
    let tokenStr = localStorage.getItem('access_token')
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })

    //Send bulk mail
    const [generalMailData, setGeneralMailData] = useState({
        'start_sequence': '',
        'end_sequence': '',
        'sender_email': '',
        'subject': '',
        'message': '',
        // 'mail_list': '',
    })
    const [showSendMailModal, setShowSendMailModal] = useState(false);
    const handleCloseSendMailModal = () => setShowSendMailModal(false);
    const handleShowSendMailModal = () => {
        setShowSendMailModal(true)
    };
    const handelGeneralMailVal = (e) => {
        const name = e.target.name
        const value = e.target.value

        setGeneralMailData((prev) => {
            return ({ ...prev, [name]: value })
        })
    }
    const handelSendMail = (e) => {
        e.preventDefault()
        setDisableSubmit(true)

        let formData = {
            'start_sequence': generalMailData.start_sequence,
            'end_sequence': generalMailData.end_sequence,
            'sender_email': `${generalMailData.sender_email}@easymeet.ws`,
            'subject': generalMailData.subject,
            'message': generalMailData.message,
        }
        Axios.post(`${baseURL}/cfp-circulation-email/`, formData, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                setShowSendMailModal(false)
                setDisableSubmit(false)

                setAlertData({
                    'alertShow': true,
                    'alertVariant': 'success',
                    'alertMessage': res.data.message
                })

            })
            .catch(
                error => {

                    setShowSendMailModal(false)
                    setDisableSubmit(false)

                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data
                    })
                }
            )
    }

    useEffect(() => {
        if (alertData.alertShow) {
            setTimeout(() => {
                setAlertData({
                    'alertShow': false,
                    'alertVariant': '',
                    'alertMessage': null
                })
            }, 2000)
        }
    }, [alertData])
    return (
        <>
            <AlertCustom data={alertData} />

            <ul className='sidebar-nav'>

                {
                    currentRole === 'ADMIN' ?
                        <>
                            {
                                menuItems.map((item) => {
                                    const { title, url, icon, submenu } = item

                                    return (
                                        <li className='subNav' key={title}>
                                            {
                                                submenu ? (
                                                    <>
                                                        <SubNavLabel title={title} url={url} icon={icon} />
                                                        {/* <SubNav data={submenu} /> */}
                                                        {
                                                            title === 'Profile' ? <SubNav data={submenu} hasLogout={true} /> : <SubNav data={submenu} />
                                                        }
                                                    </>
                                                ) : <NavLink to={url} title={title}><span className="material-icons">{icon}</span><span className='title'>{title}</span></NavLink>
                                            }
                                        </li>
                                    )
                                })
                            }
                            <li className='subNav'><a className='sideLink'
                                title='Mail Send' onClick={() => handleShowSendMailModal()}
                            ><span className="material-icons">mail_outline</span> CFP Circulation</a></li>
                        </>
                        :
                        userMenuItems.map((item) => {
                            const { title, url, icon, submenu } = item

                            return (
                                <li className='subNav' key={title}>
                                    {
                                        submenu ? (
                                            <>
                                                <SubNavLabel title={title} url={url} icon={icon} />
                                                {/* <SubNav data={submenu} /> */}
                                                {
                                                    title === 'Profile' ? <SubNav data={submenu} hasLogout={true} /> : <SubNav data={submenu} />
                                                }
                                            </>
                                        ) : <NavLink to={url} title={title}><span className="material-icons">{icon}</span><span className='title'>{title}</span></NavLink>
                                    }
                                </li>
                            )
                        })
                }
            </ul >
            <Modal Modal show={showSendMailModal} onHide={handleCloseSendMailModal} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>CFP Circulation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handelSendMail}>
                        <div className="row">
                            <div className='col-md-6'>
                                <div className="row">
                                    <div className='col-md-3'>
                                        <div className='mb-3'>
                                            <label>Start Sequence</label>
                                            <input type='number' className='form-control' name="start_sequence" value={generalMailData.start_sequence} onChange={handelGeneralMailVal} required />
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='mb-3'>
                                            <label>End Sequence</label>
                                            <input type='number' className='form-control' name="end_sequence" value={generalMailData.end_sequence} onChange={handelGeneralMailVal} required />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-md-6'>
                                        <div className='mb-3'>
                                            <label>Sender Email</label>
                                            <div class="input-group-text-with-bg input-group">
                                                <input type='text' className='form-control' name="sender_email" value={generalMailData.sender_email} onChange={handelGeneralMailVal} required />
                                                <div class="input-group-text" id="basic-addon2">@easymeet.ws</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label>Subject</label>
                                    <input type='text' className='form-control' name="subject" value={generalMailData.subject} onChange={handelGeneralMailVal} required />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label>CFP Message Body</label>
                                    <textarea className='form-control' rows={7} name="message" value={generalMailData.message} onChange={handelGeneralMailVal} required></textarea>
                                </div>
                            </div>
                            {/* <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label>Mail List <small className='text-secondary'>(Comma separated)</small></label>
                                    <textarea className='form-control' rows={12} name="mail_list" value={generalMailData.mail_list} onChange={handelGeneralMailVal} required></textarea>
                                    <p>{mailCount}</p>
                                </div>
                            </div> */}
                        </div>
                        <input type="submit" value="Submit" className='btn btn-primary' disabled={disableSubmit} />
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Sidebar