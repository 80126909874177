import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../components/elements/BreadCrumb'
import { useLocation, useParams } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import StatusList from '../../data/StatusList'
import Loader from "../../components/elements/loader/Loader";
import useAjax from "../../useAjax";
import Pagination from '../../components/elements/Pagination'
import Axios from 'axios'
import Downloading from '../../components/elements/loader/Downloading'
import { useApplicationContext } from '../../contexts/ApplicationContext'

const baseURL = process.env.REACT_APP_ENDPOINT
const tableOptions = {
    'styleClass': 'custom-datatable',
    'sortBy': 'paper_sequence',
    'sortingIconDown': 'arrow_drop_down',
    'sortingIconUp': 'arrow_drop_up',
    'searchBar': true,
    'searchBarPlaceholder': 'Search',
    'pageNo': 1,
    'perPage': true,
    'perPageLabel': 'Items per page',
    'perPageDefault': 50,
    'perPageOptions': [50, 100, 200, 500, 1000],
    'pagination': true,
    'paginationOptions': {
        'styleClass': 'pagination',
        'labelLatest': '',
        'iconLatest': 'New',
        'labelOldest': '',
        'iconOldest': 'Old',
        'labelPrevious': 'Prev',
        'iconPrevious': 'Prev',
        'iconNext': '',
        'labelNext': 'Next'
    }
}
const headers = [
    {
        'label': 'PaperID',
        'key': 'paper_sequence',
        'sortable': true
    }, {
        'label': 'Download',
        'key': '',
        'sortable': false
    }, {
        'label': 'Paper Title',
        'key': 'paper_title',
        'sortable': true
    }, {
        'label': 'Authors',
        'key': 'paper_author_data',
        'sortable': false
    }, {
        'label': 'Track',
        'key': 'track_name',
        'sortable': false
    }, {
        'label': 'Reviewers',
        'key': 'paper_reviewer_data',
        'sortable': false
    }, {
        'label': 'Status',
        'key': 'status',
        'sortable': true
    },
]

const PaperReports = () => {

    // const detailsId = useParams()
    const prevStage = useLocation()
    const [dataStage, setDataStage] = useState(prevStage)

    const detailsId = dataStage.state || 0

    const [isLoading, setIsLoading] = useState(false);
    const [isActive, setIsActive] = useState(1000);
    const [missingPaper, setMissingPaper] = useState(false);
    
    const [data, setData] = useState({})
    const [allPaper, setAllPaper] = useState([])

    const [getAllPaperCall, getAllPaperCallSuccess] = useAjax('get');
    const [getPageData, getPageDataSuccess] = useAjax('get')

    const [searchText, setSearchText] = useState(null)
    const [paramSearch, setParamSearch] = useState('')
    const [paginationCount, setPaginationCount] = useState()
    const [totalCount, setTotalCount] = useState()
    const [pageNo, setPageNo] = useState(tableOptions.pageNo)
    const [perPage, setPerPage] = useState(tableOptions.perPageDefault)
    const [sortBy, setSortBy] = useState(tableOptions.sortBy)
    const [orderType, setOrderType] = useState('ASC')
    const [sortOrder, setSortOrder] = useState(tableOptions.sortingIconDown)
    const [sortActive, setSortActive] = useState('')
    const [, dispatchApplicationContext] = useApplicationContext();


    useEffect(() => {
        if (getAllPaperCallSuccess) {
            setAllPaper(getAllPaperCallSuccess.result.data)
            setTotalCount(getAllPaperCallSuccess?.result.total);
            setPaginationCount(() => {
                return Math.ceil(getAllPaperCallSuccess?.result.total / perPage)
            })
        }
    }, [getAllPaperCallSuccess])

    useEffect(() => {
        if (getPageDataSuccess) {
            setData(getPageDataSuccess.result[0])
        }
    }, [getPageDataSuccess])

    useEffect(() => {
        getPageData({ URL: `${baseURL}/site/conf/short/${detailsId.id}` })
    }, [])

    useEffect(() => {
        getAllPaperCall({ URL: `${baseURL}/paper/all/${detailsId.id}/0?order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}` })
    }, [perPage, pageNo])


    const [fireHandleSubmit, handleSubmitSuccess] = useAjax('post');

    useEffect(() => {
        if (handleSubmitSuccess) {
            // window.location.reload()
            dispatchApplicationContext({ type: "serviceSuccess", payload: { statusMessage: handleSubmitSuccess?.message } })
            getAllPaperCall({ URL: `${baseURL}/paper/all/${detailsId.id}/0?order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}` })
        }
    }, [handleSubmitSuccess]);

    //Datatabel
    const handelSorting = (key) => {
        setSortBy(key)
        setSortActive(key === sortActive ? null : key)
    }

    const selectedPagination = (pageNumber) => {
        setPageNo(pageNumber)
    }

    const handleChangeSearchText = (e) => {
        const searchTerm = e.target.value
        setSearchText(searchTerm)
        if (searchText != null) {
            setParamSearch(`param_search=${e.target.value}&`)
        }

        if (searchTerm.length > 2) {
            getAllPaperCall({ URL: `${baseURL}/paper/all/${detailsId.id}/0?${paramSearch}order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}` })
        } else if (searchTerm.length === 0) {
            getAllPaperCall({ URL: `${baseURL}/paper/all/${detailsId.id}/0?order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}` })
        }
    }

    const downloadPaper = async (id) => {
        let tokenStr = localStorage.getItem('access_token')
        setIsLoading(true);
        Axios.get(`${baseURL}/paper/${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(
                (res) => {
                    setIsLoading(false);
                    const byteCharacters = atob(res.data.result.paper_file_data);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: res.data.result.paper_file_mime_type });
                    // Create a link element
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = res.data.result.paper_sequence;

                    // Append to the document, trigger click, and remove
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    // Release the object URL
                    window.URL.revokeObjectURL(link.href);
                }
            ).catch((error) => {
                console.log(error)
            })
    }

    const statusText = (input) => {
        const result = StatusList.find(status => status.value == input)
        return result.label
    }
    const callPaperApi = (input)=>{
        if(input == 1000){
            setMissingPaper(false)
            getAllPaperCall({ URL: `${baseURL}/paper/all/${detailsId.id}/0?${paramSearch}order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}` })
        }else if(input == 1010){
            getAllPaperCall({ URL: `${baseURL}/paper/all/${detailsId.id}/0?${paramSearch}order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}&missing_paper=true` })
            setMissingPaper(true)
        }else{
            setMissingPaper(false)
            getAllPaperCall({ URL: `${baseURL}/paper/all/${detailsId.id}/0?${paramSearch}order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}&status=${input}` })
        }
        setIsActive(input)
    }

    return (
        <>
            <BreadCrumb title={`Reports - ${data.acronym_title}`} from='Conference' to='Reports' back={true} />
            {isLoading && <Downloading />}

            <div className="d-block card elevated p-3 mb-3">
                <button class={`btn btn-classic btn-light btn-sm mb-2 me-2 elevated ${isActive === 1000 ? 'active': ''}`} onClick={()=>callPaperApi(1000)}>All</button>
                <button class={`btn btn-classic btn-light btn-sm mb-2 me-2 elevated ${isActive === 0 ? 'active': ''}`} onClick={()=>callPaperApi(0)}>Submitted</button>
                <button class={`btn btn-classic btn-light btn-sm mb-2 me-2 elevated ${isActive === 25 ? 'active': ''}`} onClick={()=>callPaperApi(25)}>Blocked</button>
                <button class={`btn btn-classic btn-light btn-sm mb-2 me-2 elevated ${isActive === 30 ? 'active': ''}`} onClick={()=>callPaperApi(30)}>Withdrawn</button>
                {/* <button class={`btn btn-classic btn-light btn-sm mb-2 me-2 elevated ${missingPaper ? 'active': ''}`} onClick={()=>callPaperApi(1010)}>Missing Paper</button> */}
            </div>
            <div className="card elevated p-3">
                <div className="d-flex justify-content-between pb-3 table-filter-control">
                    <div>
                        <input type="search" name="table-global" placeholder={tableOptions.searchBarPlaceholder} onChange={handleChangeSearchText} />
                    </div>
                    <div>
                        <select name="h" value={perPage} onChange={(e) => setPerPage(e.target.value)}>
                            {
                                tableOptions.perPageOptions.map((item) => {
                                    return (
                                        <option value={item}>{item}</option>
                                    )
                                })
                            }
                        </select>
                        <span className="ml-1">{tableOptions.perPageLabel}</span>
                    </div>
                </div>
                {
                    allPaper.length > 0 ? (
                        <Table striped bordered hover className='custom-datatable' style={{ fontSize: '12px' }}>
                            <thead>
                                <tr>
                                    {
                                        headers.map((item) => {
                                            const { label, key, sortable } = item
                                            return (
                                                sortable ? <th onClick={() => handelSorting(key)} className={`sorting ${key === sortActive ? 'active' : ''}`}>
                                                    <span>{label}</span>
                                                    <span className="material-icons">
                                                        {sortOrder}
                                                    </span>
                                                </th> : <th >{label}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allPaper.length > 0 ?
                                        allPaper.map((item) => {
                                            const {
                                                id,
                                                paper_title,
                                                paper_sequence,
                                                paper_file_path,
                                                paper_author_data,
                                                track_name,
                                                paper_reviewer_data,
                                                status
                                            } = item
                                            return (
                                                <tr key={id}>
                                                    <td><span className='badge bg-primary'>{paper_sequence}</span></td>
                                                    <td>
                                                        {
                                                            paper_file_path ?
                                                                <button className='btn btn-primary btn-xs' onClick={() => downloadPaper(id)}><span
                                                                    className="material-icons">file_download</span></button>
                                                                : 'Paper not uploaded'
                                                        }
                                                    </td>
                                                    <td>{paper_title}</td>
                                                    <td>
                                                        <div className='maxh-7'>
                                                            {
                                                                paper_author_data.length > 0 ?
                                                                    paper_author_data.map((item) => {
                                                                        const { author_first_name, author_last_name, author_email } = item
                                                                        return (
                                                                            <div className='mb-2'>{author_first_name} {author_last_name}
                                                                                <br />{author_email}</div>
                                                                        )
                                                                    })
                                                                    : ''
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>{track_name}</td>
                                                    <td>
                                                        {
                                                            paper_reviewer_data.length > 0 ?
                                                                paper_reviewer_data.map((item) => {
                                                                    const { first_name, last_name, email } = item
                                                                    return (
                                                                        <div className='mb-3'>{first_name} {last_name}
                                                                            <br />{email}</div>
                                                                    )
                                                                })
                                                                : ''
                                                        }
                                                    </td>
                                                    <td>
                                                        {statusText(status)}
                                                    </td>
                                                </tr>
                                            )
                                        }) : "No record found"
                                }
                            </tbody>
                        </Table>
                    ) : <div>No results found.</div>
                }
                {paginationCount > 1 && (
                    <div className="d-flex justify-content-between">
                        <Pagination data={paginationCount} callback={selectedPagination} options={tableOptions.paginationOptions} />
                        <div>
                            Total <strong>{totalCount}</strong> records
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default PaperReports