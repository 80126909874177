import React from 'react'
import BreadCrumb from '../../components/elements/BreadCrumb'

const ManagePermission = () => {
  return (
    <>
    <BreadCrumb title='Permission Settings' from='Settings' to='Manage Permissions' back={true} />
    </>
  )
}

export default ManagePermission