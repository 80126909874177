const ReviewerConfidence = [
    {
        'value': 'No Decision',
        'label': 'No Decision',
    },
    {
        'value': 'Probably Accept',
        'label': 'Probably Accept',
    }, {
        'value': 'Accept',
        'label': 'Accept',
    }, {
        'value': 'Probably Reject',
        'label': 'Probably Reject',
    }, {
        'value': 'Reject',
        'label': 'Reject',
    }
]

export default ReviewerConfidence