import Axios from 'axios'
import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
const baseURL = process.env.REACT_APP_ENDPOINT

const SubNav = (props) => {
    const data = props.data
    const logoutOption = props.hasLogout
    const navigate = useNavigate()

    //Handel logout
    const handelLogout = () => {

        let tokenStr = localStorage.getItem('access_token')

        Axios.post(`${baseURL}/logout`, null, { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${tokenStr}` } })
            .then((response) => {                
                return(
                localStorage.clear(),
                navigate('/')
                )
            }
            ).catch((error) => {
                console.log(error)
                navigate('/')
            })

    }

    return (
        <ul className={`list-unstyled subNavDropdown`}>
            {
                data.map((subItems) => {
                    const { title, url } = subItems
                    return (
                        <li key={title}>
                            <NavLink to={url} title={title}>
                                {title}
                            </NavLink>
                        </li>
                    )
                })

            }
            {
                // console.log(logoutOption)
                logoutOption ? <li><a className='cursor-pointer' onClick={handelLogout}>Logout</a></li> : ""

            }
        </ul>

    )
}

export default SubNav