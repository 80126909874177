import React from 'react'
import BreadCrumb from '../../components/elements/BreadCrumb'

const ManageUserGroup = () => {
  return (
    <>
    <BreadCrumb title='User Role Settings' from='Settings' to='Role Management' back={true} />

    </>
  )
}

export default ManageUserGroup