const ResearchArea = [
    {
        'value': 1,
        'label': 'Accounting and Finance',
    },
    {
        'value': 2,
        'label': 'Art and Humanities',
    }, {
        'value': 3,
        'label': 'Biological Sciences',
    }, {
        'value': 4,
        'label': 'Business and Management',
    }, {
        'value': 5,
        'label': 'Chemistry',
    }, {
        'value': 6,
        'label': 'Civil Engineering',
    }, {
        'value': 7,
        'label': 'Computing',
    }, {
        'value': 8,
        'label': 'Earth and Environmental Sciences',
    }, {
        'value': 9,
        'label': 'Economics',
    }, {
        'value': 10,
        'label': 'Education Science',
    }, {
        'value': 11,
        'label': 'Energy',
    }, {
        'value': 12,
        'label': 'Engineering',
    }, {
        'value': 13,
        'label': 'Genomics and Bioinformatics',
    }, {
        'value': 14,
        'label': 'Health Sciences',
    }, {
        'value': 15,
        'label': 'Language and Linguistics',
    }, {
        'value': 16,
        'label': 'Materials Science',
    }, {
        'value': 17,
        'label': 'Mathematics and Statistics',
    }, {
        'value': 18,
        'label': 'Mechanical Engineering',
    }, {
        'value': 19,
        'label': 'Physics',
    }, {
        'value': 20,
        'label': 'Social Sciences',
    }, {
        'value': 21,
        'label': 'Technology',
    }
]

export default ResearchArea