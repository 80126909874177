import React, {useEffect, useState} from 'react';
import {FloatingLabel, Form, Modal} from "react-bootstrap";
import CountryList from "../../data/CountryList";
import useAjax from "../../useAjax";
import Loader from "../../components/elements/loader/Loader";
const baseURL = process.env.REACT_APP_ENDPOINT

const EditProfileModal = ({profileId, onProfileUpdateComplete}) => {
    const [showProfileEditModal, setShowProfileEditModal] = useState(false);
    const [updateProfile, profileUpdateSuccess, updateUserDataProcessing] = useAjax('post', `${baseURL}/users/update`)
    const [getSingleUserDataCall, singleUserData, singleUserDataLoading] = useAjax('get')
    const [editData, setEditData] = useState([])

    const handleCloseProfileEditModal = () => setShowProfileEditModal(false);
    const handleSubmitProfileEdit = (e) => {
        e.preventDefault();
        const confirmation = window.confirm("Are you sure to update the User?")
        if (confirmation) {
            updateProfile({ payload: editData });
        }
    }

    const handleChangeEdit = (e) => {
        let name = e.target.name
        let value = e.target.value

        setEditData((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }
    const getSingleUserData = (id) => {
        getSingleUserDataCall({ URL: `${baseURL}/users/${id}` });
    }

    useEffect(()=>{
        onProfileUpdateComplete(profileId);
        setShowProfileEditModal(false);
    },[profileUpdateSuccess])

    useEffect(()=>{
        if(profileId){
            setShowProfileEditModal(true);
            getSingleUserData(profileId)
        }
    }, profileId);

    useEffect(() => {
        if (singleUserData?.result) {
            const result = singleUserData?.result[0];

            setEditData({
                user_id: result.id,
                first_name: result.first_name,
                last_name: result.last_name,
                country: result.country,
                affiliation: result.affiliation
            });
        }
    }, [singleUserData])

    return (
        <>
            <Modal Modal show={showProfileEditModal} onHide={handleCloseProfileEditModal}>
                {singleUserDataLoading || updateUserDataProcessing ? (<Loader />) : (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Profile</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleSubmitProfileEdit}>
                                <div className='mb-3'>
                                    <FloatingLabel className='mb-3' controlId="" label="First Name">
                                        <Form.Control type="text" placeholder="First Name" name='first_name'
                                                      value={editData.first_name} onChange={handleChangeEdit} required />
                                    </FloatingLabel>
                                    <FloatingLabel className='mb-3' controlId="" label="Last Name">
                                        <Form.Control type="text" placeholder="Last Name" name='last_name'
                                                      value={editData.last_name} onChange={handleChangeEdit} />
                                    </FloatingLabel>
                                    <FloatingLabel className='mb-3' controlId="" label="Country">
                                        {/* <Form.Control type="text" placeholder="Country" name='country' value={editData.country} onChange={handleChangeEdit} required /> */}
                                        <Form.Select name='country' value={editData.country} onChange={handleChangeEdit}
                                                     required>
                                            <option>Select</option>
                                            {
                                                CountryList.map((item, key) => {
                                                    return <option value={item} key={key}>{item}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </FloatingLabel>
                                    <FloatingLabel className='mb-3' controlId="" label="Affiliation">
                                        <Form.Control type="text" placeholder="Affiliation" name='affiliation'
                                                      value={editData.affiliation} onChange={handleChangeEdit} required />
                                    </FloatingLabel>

                                </div>

                                    <div className='text-end'>
                                        <button className="btn btn-clear secondary " onClick={handleCloseProfileEditModal}
                                                type='reset'>Cancel
                                        </button>
                                        <button className="btn ms-3 btn-primary" type='submit'>Submit</button>
                                    </div>


                            </Form>
                        </Modal.Body>
                    </>
                )
                }


            </Modal>
        </>

    );
};

export default EditProfileModal;